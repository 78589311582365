// @flow

import AccessDataElement from "./AccessDataElement";
import React from "react";
import {Form} from "react-bootstrap";
import {CreateRecordButton} from "../../../Helper/Buttons";

type AccessDatasProps = {
    formAddon: Addon,
    createAccessDataElement: Function,
    deleteAccessDataElement: Function,
    handleDataRecordValueChange: Function,
    handleAccessDataSelectChange: Function,
    createAccessDataType: Function,
    trimDataRecordValue: Function,
    errorMessages: [],
}

export default function AccessDatas(
    {
        formAddon,
        createAccessDataElement,
        deleteAccessDataElement,
        handleDataRecordValueChange,
        handleAccessDataSelectChange,
        createAccessDataType,
        trimDataRecordValue,
        errorMessages
    }: AccessDatasProps
) {
    return (
        <>
            <div className="item-100">
                <div className="flex justify-content-between">
                    <h5 className="form-part-title">Zugangsdaten</h5>
                </div>
                <AccessDataLabels />
                <AccessDataElements
                    accessDatas={formAddon.accessDatas}
                    deleteAccessDataElement={deleteAccessDataElement}
                    handleDataRecordValueChange={handleDataRecordValueChange}
                    handleAccessDataSelectChange={handleAccessDataSelectChange}
                    createAccessDataType={createAccessDataType}
                    trimDataRecordValue={trimDataRecordValue}
                    errorMessages={errorMessages}
                />
                <CreateRecordButton
                    name="addAccessDataRecord"
                    title="Zugangsdaten"
                    createFunction={createAccessDataElement}
                />
            </div>
        </>
    )
}


function AccessDataLabels() {
    return (
        <>
            <Form.Row>
                <div className="flex justify-content-between access-data-form">
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Form.Label>Typ</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Form.Label>Name / Kurze Beschreibung</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Form.Label>Login Username / E-Mail</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Form.Label>Passwort</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Form.Label>Anmerkung</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="access-data-form-button"/>
                </div>
            </Form.Row>
        </>
    );
}

type AccessDataElementsProps = {
    accessDatas: null|[],
    deleteAccessDataElement: Function,
    handleDataRecordValueChange: Function,
    handleAccessDataSelectChange: Function,
    createAccessDataType: Function,
    trimDataRecordValue: Function,
    errorMessages: Function,
}

function AccessDataElements(
    {
        accessDatas,
        deleteAccessDataElement,
        handleDataRecordValueChange,
        handleAccessDataSelectChange,
        createAccessDataType,
        trimDataRecordValue,
        errorMessages
    }: AccessDataElementsProps
) {
    if (!accessDatas) {
        return null;
    }

    return (
        accessDatas.map((
            accessData:
                {
                    accessDataId: string,
                    accessDataType: string,
                    accessDataTypeId: string,
                    accessDataName: string,
                    accessDataEmail: string,
                    accessDataEmailPassword: string,
                    accessDataAnnotation: string,
                },
            key
        ) => (
            <AccessDataElement
                key={accessData.accessDataId}
                index={key}
                accessDataType={accessData.accessDataType}
                accessDataTypeId={accessData.accessDataTypeId}
                accessDataName={accessData.accessDataName}
                accessDataEmail={accessData.accessDataEmail}
                accessDataEmailPassword={accessData.accessDataEmailPassword}
                accessDataAnnotation={accessData.accessDataAnnotation}
                deleteAccessDataElement={deleteAccessDataElement}
                handleDataRecordValueChange={handleDataRecordValueChange}
                handleAccessDataSelectChange={handleAccessDataSelectChange}
                createAccessDataType={createAccessDataType}
                trimDataRecordValue={trimDataRecordValue}
                errorMessages={errorMessages}
            />
        ))
    );
}
