// @flow

import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import {Alert, Button, Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import FormFeedback from "reactstrap/es/FormFeedback";
import {Redirect} from "react-router-dom";
import {loginApi} from "../Api/AuthenticationApi";
import {aboutMeApi} from "../Api/AuthorizationApi";

export default function () {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoginAttempt, setIsLoginAttempt] = useState(false);

    useEffect(() => {
        if (!isLoginAttempt) {
            fetchAuthorizationApi();
        }

        async function fetchAuthorizationApi(): Promise<void> {

            const response = await aboutMeApi();
            setIsLoggedIn(response.status === 200)
        }
    }, [isLoginAttempt]);

    const submit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        const response = await loginApi(email, password);

        setError(response.error);
        setErrorMessage(response.errorMessage);
        setIsLoginAttempt(false);
    };

    if (error === false || isLoggedIn) {
        return (
            <Redirect to="/addon/list" />
        );
    }

    return (
        <>
            <div className="login">
                <Card className="login-card">
                    <Card.Header className="login-header">Login</Card.Header>
                    <Card.Body>
                        <Form>
                            <FormGroup>
                                <FormLabel>E-Mail</FormLabel>
                                <FormControl
                                    type="email"
                                    name="email"
                                    onChange={handleChange.bind(this, setEmail)}
                                    required
                                />
                                <FormFeedback>Invalides Feld</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Passwort</FormLabel>
                                <FormControl
                                    type="password"
                                    name="password"
                                    onChange={handleChange.bind(this, setPassword)}
                                    required
                                />
                                <FormFeedback>Invalides Feld</FormFeedback>
                            </FormGroup>
                            <Button
                                type="submit"
                                name="login"
                                onClick={submit}
                                title="login"
                                size="lg"
                                variant="outline-dark"
                            >
                                Login
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <div className="login-error">
                    {renderErrorMessages()}
                </div>
            </div>
        </>
    );

    function handleChange(setter: function, event: SyntheticInputEvent<HTMLInputElement>) {
        setIsLoginAttempt(true);
        setter(event.target.value);
    }

    function renderErrorMessages() {
        if (error) {
            return (
                <Alert variant="danger">{ errorMessage }</Alert>
            )
        }
    }
}
