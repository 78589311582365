// @flow

import React from 'react';
import {render} from 'react-dom';
import Router from "./Router";
import 'nprogress/nprogress.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/customNProgress.scss';
import './scss/custom.scss';
import './scss/customDatePicker.scss';
import './scss/customReactSelect.scss';

document.addEventListener('DOMContentLoaded', function (): void {
    const element = document.querySelector('#main');
    if (element){
        render(<Router/>, element);
    } else {
        console.error("#main element not found")
    }
});
