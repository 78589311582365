// @flow

import type {User} from "../User/UserList";

export type CreateUserResponse = {
    success: ?string,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type UserListResponse = {
    users: User[],
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type UserResponse = {
    user: User,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type ChangeUserEmailResponse = {
    success: ?string,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type ChangeUserNameResponse = {
    success: ?string,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type ChangeUserRoleResponse = {
    success: ?string,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export async function loadUsersFromApi(): Promise<UserListResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/user/list', {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            users: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            users: [],
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function loadUserFromApi(userId: string): Promise<UserResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/user/user/' + userId, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            user: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            user: {},
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function createUserApi(email: string, password: string, name: string, userRole: string): Promise<CreateUserResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'create_user',
                payload: {
                    email: email,
                    password: password,
                    name: name,
                    userRole: userRole,
                }
            }),
        });

        const data = await response.json();

        return {
            success: data,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
          return {
              success: null,
              error: true,
              errorMessage: e.message,
              status: response ? response.status : null,
          }
    }
}

export async function changeUserEmailApi(userId: string, email: string): Promise<ChangeUserEmailResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'change_email',
                payload: {
                    userId: userId,
                    email: email,
                }
            }),
        });

        const data = await response.json();

        return {
            success: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            success: null,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function changeUserNameApi(userId: string, name: string): Promise<ChangeUserNameResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'change_user_name',
                payload: {
                    userId: userId,
                    name: name,
                }
            }),
        });

        const data = await response.json();

        return {
            success: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            success: null,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function changeUserRoleApi(userId: string, role: string): Promise<ChangeUserRoleResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'change_role',
                payload: {
                    userId: userId,
                    userRole: role,
                }
            }),
        });

        const data = await response.json();

        return {
            success: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            success: null,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
