// @flow

import React from "react";
import {Form} from "react-bootstrap";
import Feedback from "../Feedback";

type StoreDescriptionProps = {
    storeDescription: {
        storeDescription: null|string,
    },
    handleTextChange: Function,
    trimTextValues: Function,
    errorMessages: [],
    inputValueReachedMaxLength: {},
}

export default function StoreDescription({storeDescription, handleTextChange, trimTextValues, errorMessages, inputValueReachedMaxLength}: StoreDescriptionProps) {
    if (storeDescription) {
        return (
            <>
                <div className="further-information-form-element">
                    <h5 className="form-part-title">Store Beschreibung</h5>
                    <Form.Group>
                        <Form.Label>Store Beschreibung <p className="d-inline text-muted small">(max. 65.000 Zeichen)</p>
                        </Form.Label>
                        <div className="furhter-information-input-textarea-height">
                            <Form.Control
                                isInvalid={inputValueReachedMaxLength.storeDescription}
                                size="sm"
                                as="textarea"
                                rows={12}
                                name="storeDescription"
                                value={storeDescription.storeDescription || ''}
                                onChange={handleTextChange.bind(this, 'storeDescription', 'storeDescription')}
                                onBlur={trimTextValues.bind(this, 'storeDescription', 'storeDescription')}
                                placeholder="Store Beschreibung des Addons"
                            />
                            <Form.Control.Feedback type="invalid">
                                Es können max. 65.000 Zeichen gespeichert werden!
                            </Form.Control.Feedback>
                            <Feedback errorMessages={errorMessages} fieldName={'storeDescription'}/>
                        </div>
                    </Form.Group>
                </div>
                <div className="further-information-hidden-placeholder"/>
            </>
        );
    }

    return null;
}
