// @flow

import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Form} from "react-bootstrap";
import FormFeedback from "reactstrap/es/FormFeedback";
import {createUserApi} from "../Api/UserApi";
import NotAuthorizedError from "../Authorization/NotAuthorizedError";
import {loadDropdownValuesFromApi} from "../Api/DropdownApi";

export default function () {
    const dropdownDefaultValue = 'Bitte auswählen';
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
    const [name, setName] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [isNameInvalid, setIsNameInvalid] = useState(false);
    const [userRole, setUserRole] = useState('');
    const [isRoleValid, setIsRoleValid] = useState(false);
    const [isRoleInvalid, setIsRoleInvalid] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [areUserRolesLoaded, setAreUserRolesLoaded] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!areUserRolesLoaded) {
            loadUserRoles();
            setAreUserRolesLoaded(true);
        }
    }, [areUserRolesLoaded]);

    async function loadUserRoles() {
        const response = await loadDropdownValuesFromApi('userRole');

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        setUserRoles(response.values);
    }

    const submit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isEmailValid && isPasswordValid && isNameValid && isRoleValid) {

            const response = await createUserApi(email, password, name, userRole);

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            } else {
                if (response.status === 500) {
                    setError(true);
                    setSuccess(false);
                    setErrorMessage('Nicht alle Eingaben sind korrekt')
                } else {
                    setSuccess(response.success);
                    setError(response.error);
                    setErrorMessage(response.errorMessage);
                }
            }
        } else {
            setError(true);
            setErrorMessage('Nicht alle Eingaben sind korrekt')
        }
    };

    return(
        <>
            <Card body="true">
                <Card.Title>Benutzer anlegen</Card.Title>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>E-Mail</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                autoComplete="new-password"
                                onChange={validateAndHandleChange.bind(
                                    this,
                                    'email',
                                    setEmail,
                                    setIsEmailValid,
                                    setIsEmailInvalid
                                )}
                                isValid={isEmailValid}
                                isInvalid={isEmailInvalid}
                                placeholder="E-Mail des Benutzers"
                                required
                            />
                            <FormFeedback>Invalides Feld</FormFeedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                autoComplete="new-password"
                                onChange={validateAndHandleChange.bind(
                                    this,
                                    'password',
                                    setPassword,
                                    setIsPasswordValid,
                                    setIsPasswordInvalid
                                )}
                                isValid={isPasswordValid}
                                isInvalid={isPasswordInvalid}
                                placeholder="Passwort des Benutzers"
                                required
                            />
                            <FormFeedback>Invalides Feld</FormFeedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                autoComplete="new-password"
                                onChange={validateAndHandleChange.bind(
                                    this,
                                    'name',
                                    setName,
                                    setIsNameValid,
                                    setIsNameInvalid
                                )}
                                isValid={isNameValid}
                                isInvalid={isNameInvalid}
                                placeholder="Name des Benutzers"
                                required
                            />
                            <FormFeedback>Invalides Feld</FormFeedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Rolle</Form.Label>
                            <Form.Control
                                as="select"
                                name="userRole"
                                onChange={validateAndHandleChange.bind(
                                    this,
                                    'userRole',
                                    setUserRole,
                                    setIsRoleValid,
                                    setIsRoleInvalid,
                                )}
                                required
                            >
                                <option>{dropdownDefaultValue}</option>
                                {userRoles.map((userRole: {"id": string, "userRole": string}, key) => (
                                    <option key={key} value={userRole.id}>{userRole.userRole}</option>
                                ))}
                            </Form.Control>
                            <FormFeedback>Invalides Feld</FormFeedback>
                        </Form.Group>
                        <Button
                            type="submit"
                            name="save"
                            onClick={submit}
                            title="Speichern"
                            variant="outline-success"
                        >
                            Speichern
                        </Button>
                    </Form>
                    {renderResultMessage()}
                </Card.Body>
            </Card>
        </>
    );

    function validateAndHandleChange(
        key: string,
        valueSetter: function,
        validSetter: function,
        invalidSetter: function,
        event: SyntheticInputEvent<HTMLInputElement>
    ): void {
        if (key === 'userRole') {
            validSetter(true);
            invalidSetter(false);
            valueSetter(event.target.value);

            return;
        }

        let isValid;

        setSuccess(false);
        setError(false);

        if (event.target.value.length >= 3 && event.target.value.length <= 255) {
            isValid = true;

            if (key === 'email') {
                isValid = event.target.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
            }
        } else {
            isValid = false;
        }

        if (isValid) {
            valueSetter(event.target.value);
        }

        validSetter(isValid);
        invalidSetter(!isValid);
    }

    function renderResultMessage() {
        if (error && !success) {
            return <Alert variant={"danger"}>{errorMessage}</Alert>;
        }

        if (success && !error) {
            return <Alert variant={"success"}>Benutzer {name} erstellt</Alert>;
        }
    }
}
