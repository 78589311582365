// @flow

export function formatLangugeValuesForFrontend(
    addons: FilteredAddon[],
    languages: [{ISO_639_2: string, language: string}]
): FilteredAddon[] {
    let languagesMapping = {};
    languages.map((language: {ISO_639_2: string, language: string}) => (
        languagesMapping[language.ISO_639_2] = language.language
    ));

    addons = addons.map((addon: FilteredAddon) => {
        if (addon.language) {
            addon.language = languagesMapping[addon.language];
        }

        return addon;
    })

    return addons;
}

export function getFilteredLanguageOptions(
    languages: [
        {
            ISO_639_2: string,
            language: string,
        },
    ],
    selectedlanguages: []
): []|[{value: string, label: string}] {
    let filteredOptions = [];

    for (let i = 0; i < languages.length; i++) {
        if (selectedlanguages.includes(languages[i].ISO_639_2)) {
            filteredOptions.push({value: languages[i].ISO_639_2, label: languages[i].language})
        }
    }

    return filteredOptions;
}
