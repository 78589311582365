// @flow

import React from "react";
import {Alert} from "react-bootstrap";

type FeedbackProps = {
    errorMessages: [],
    fieldName: string,
}

export default function Feedback({errorMessages, fieldName}: FeedbackProps) {
    return errorMessages.map((inputfield: {"fieldName": string, "errorMessage": string}) => {
        if (inputfield.fieldName === fieldName && inputfield.errorMessage !== '') {

            return <Alert key={inputfield.fieldName} variant="warning">{inputfield.errorMessage}</Alert>
        }

        return null;
    });
}
