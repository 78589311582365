// @flow

export function getColumnName(filterKey: string): string {
    const columnNameMapping = {
        consecutiveAddonId: 'ID',
        addonName: 'Name',
        platform: 'Plattform',
        plannedLaunchDate: 'Geplanter Start',
        language: 'Sprache',
        developer: 'Entwickler',
        storePublisher: 'Store Publisher',
        storeWebmaster: 'Store Webmaster',
        appName: 'App Name',
        domain: 'Domain',
        mobileNumber: 'Handynummer',
        ownerName: 'Autor',
        ownerNickname: 'Autor Nickname',
        ownerPostalCode: 'Autor PLZ',
        ownerAddressLocality: 'Autor Ort',
        ownerStreet: 'Autor Straße',
        ownerHouseNumber: 'Autor Hausnr.',
        ownerCountry: 'Autor Land',
        ownerBirthday: 'Autor Geburtstag',
        ownerEmail: 'Autor E-Mail',
    }

    return columnNameMapping[filterKey] ? columnNameMapping[filterKey] : '';
}

export function getPlaceholder(filterKey: string): string {
    const columnNameMapping = {
        consecutiveAddonId: 'Freitext',
        addonName: 'Freitext',
        platform: 'Freitext',
        plannedLaunchDate: 'yyyy-mm-dd',
        language: '',
        developer: 'Freitext',
        storePublisher: 'Freitext',
        storeWebmaster: 'Freitext',
        appName: 'Freitext',
        domain: 'Freitext',
        mobileNumber: 'Freitext',
        ownerName: 'Freitext',
        ownerNickname: 'Freitext',
        ownerPostalCode: 'Freitext',
        ownerAddressLocality: 'Freitext',
        ownerStreet: 'Freitext',
        ownerHouseNumber: 'Freitext',
        ownerCountry: 'Freitext',
        ownerBirthday: 'yyyy-mm-dd',
        ownerEmail: 'Freitext',
    }

    return columnNameMapping[filterKey] ? columnNameMapping[filterKey] : '';
}

export function getLanguageFilterOptions(
    languages: [
        {
            ISO_639_2: string,
            language: string
        },
    ]
): [{value: string, label: string}] {
    return  languages.map((language: {ISO_639_2: string, language: string}) => (
        {value: language.ISO_639_2, label: language.language}
    ));
}
