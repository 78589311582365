// @flow

import React, {useState} from "react";
import {Button, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {logoutApi} from "../Api/AuthenticationApi";
import {Redirect} from "react-router-dom";
import Form from "react-bootstrap/Form";

type Props = {
    role: string,
}

export default function Navigation(props: Props) {
    const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);

    async function logout() {
        const response = await logoutApi();

        if (response.status === 200) {
            setIsLoggedOut(true);
        }
    }

    if (isLoggedOut) {
        return (
            <Redirect to="/login" />
        );
    }

    if (!isLoggedOut && props.role === "Admin") {
        return (
            <>
                <Navbar expand="xl">
                    <Navbar.Brand href="/">Addon-Manager</Navbar.Brand>
                    <Nav>
                        <NavDropdown id="basic-nav-dropdown" title="Addons">
                            <NavDropdown.Item href="/addon/list">Liste</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown id="basic-nav-dropdown" title="Benutzer">
                            <NavDropdown.Item href="/user/list">Liste</NavDropdown.Item>
                            <NavDropdown.Item href="/user/create">Erstellen</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown id="basic-nav-dropdown" title="Zugangsdaten">
                            <NavDropdown.Item href="/accessData/list">Liste</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Navbar.Collapse className="justify-content-end">
                        <Form inline>
                            <Button variant="outline-dark" onClick={logout}>Logout</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
            </>
        );
    }

    if (!isLoggedOut && props.role === "Owner") {
        return (
            <>
                <Navbar expand="xl">
                    <Navbar.Brand href="/">Addon-Manager</Navbar.Brand>
                    <Nav>
                        <NavDropdown id="basic-nav-dropdown" title="Addons">
                            <NavDropdown.Item href="/addon/list">Liste</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown id="basic-nav-dropdown" title="Benutzer">
                            <NavDropdown.Item href="/user/list">Liste</NavDropdown.Item>
                            <NavDropdown.Item href="/user/create">Erstellen</NavDropdown.Item>
                        </NavDropdown>
                        <Button onClick={logout}>Logout</Button>
                    </Nav>
                </Navbar>
            </>
        );
    }
}
