// @flow

import React from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import NotFound from "./NotFound";
import Forbidden from "./Authorization/Forbidden";
import AddonList from "./Addon/AddonList/AddonList";
import AddonForm from "./Addon/EditAddonForm/AddonForm";
import CreateUser from "./User/CreateUser";
import UserList from "./User/UserList";
import EditUser from "./User/EditUser";
import Login from "./Authorization/Login";
import Dashboard from "./Dashboard/Dashboard";
import AccessDataList from "./AccessData/AccessDataList";

const all = "Admin, Owner, Development, Graphic, Privacy";

const Router = () => (
  <BrowserRouter>
    <Switch>
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/forbidden"} component={Forbidden} />
        <PrivateRoute exact path={"/"} component={Dashboard} roles={all} />
        <PrivateRoute exact path={"/addon/list"} component={AddonList} roles={all} />
        <PrivateRoute exact path={"/addon/edit/:addonId"} component={AddonForm} roles={"Admin, Owner"} />
        <PrivateRoute exact path={"/accessData/list"} component={AccessDataList} roles={"Admin, Owner"} />
        <PrivateRoute exact path={"/user/list"} component={UserList} roles={"Admin, Owner"} />
        <PrivateRoute exact path={"/user/create"} component={CreateUser} roles={"Admin, Owner"} />
        <PrivateRoute exact path={"/user/edit/:userId"} component={EditUser} roles={"Admin, Owner"} />
        <PrivateRoute exact={false} path={""} component={NotFound} roles={all} />
      </Switch>
  </BrowserRouter>
);

export default Router;
