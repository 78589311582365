// @flow

import type {AddonListResponse} from "./AddonApi";

export type XlsxExportResponse = {
    xlsxFile: {
        content: string,
        name: string,
        type: string,
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export type ResetDefaultTemplateResponse = {
    body: {
        body: {
            templateId: string,
            userIdentifier: string,
        }
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export type DefaultTemplateResponse = {
    body: {
        templateId: string,
        templateKeys: [],
        templateName: string,
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export type DefaultTemplateIdResponse = {
    templateId: string,
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export async function addonListXlsxExportApi(xlsxExportData: {addonIds: [], keys: []}): Promise<XlsxExportResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/xlsx/export', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                payload: {
                    path: '/tmp/',
                    addonIds: xlsxExportData.addonIds,
                    keys: xlsxExportData.keys,
                },
            }),
        });

        const data = await response.json();

        return {
            xlsxFile: data,
            success: true,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
        return {
            response: {},
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function addonListSetDefaultTemplate(templateId: string): Promise<DefaultTemplateResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'change_user_default_template',
                payload: {
                    templateId: templateId,
                },
            }),
        });

        const data = await response.json();

        return {
            body: data,
            success: true,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
        return {
            body: {},
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function resetAddonListDefaultTemplate(): Promise<ResetDefaultTemplateResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'reset_user_default_template',
            }),
        });

        const data = await response.json();

        return {
            body: data,
            success: true,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
        return {
            body: {},
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function loadUserDefaultTemplate(): Promise<DefaultTemplateResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/user/defaultTemplate', {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            body: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            body: '',
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function loadUserDefaultTemplateId(): Promise<DefaultTemplateIdResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/user/defaultTemplateId', {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            templateId: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            body: '',
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
