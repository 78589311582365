// @flow

import React from "react";
import {Col, Form} from "react-bootstrap";
import DatePicker from "react-datepicker/es";
import de from "date-fns/locale/de";
import {formatDateForDatePicker} from "../../Helper/DateFormatter";
import Feedback from "../Feedback";

export default function OwnerInformation(
    {
        ownerInformation,
        countries,
        handleChange,
        handleTextChange,
        handleDateValueChange,
        trimValue,
        trimTextValues,
        errorMessages
    }: OwnerInformationProps
) {
    const dropdownDefaultValue = 'Bitte auswählen';

    if (!ownerInformation) {
        return null;
    }

    let country = '';
    if (ownerInformation.ownerCountry !== null) {
        country = ownerInformation.ownerCountry;
    }

    let ownerBirthday = null;
    if (ownerInformation.ownerBirthday !== null) {
        ownerBirthday = ownerInformation.ownerBirthday;

        if (typeof ownerInformation.ownerBirthday === "string") {
            ownerBirthday = formatDateForDatePicker(ownerInformation.ownerBirthday);
        }
    }

    return (
        <>
            <div className="item-50">
                <h5 className="form-part-title">Inhaber Informationen</h5>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="ownerName"
                        value={ownerInformation.ownerName || ''}
                        onChange={handleChange.bind(this, 'ownerInformation')}
                        onBlur={trimValue.bind(this, 'ownerInformation')}
                        placeholder="Name des Inhabers"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'ownerName'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Author/Nickname</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="ownerNickname"
                        value={ownerInformation.ownerNickname || ''}
                        onChange={handleChange.bind(this, 'ownerInformation')}
                        onBlur={trimValue.bind(this, 'ownerInformation')}
                        placeholder="Nickname des Inhabers"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'ownerNickname'}/>
                </Form.Group>
                <Form.Row>
                    <div className="input-number input-margin-left">
                        <Form.Group as={Col}>
                            <Form.Label>PLZ</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                name="ownerPostalCode"
                                value={ownerInformation.ownerPostalCode || ''}
                                onChange={handleChange.bind(this, 'ownerInformation')}
                                onBlur={trimValue.bind(this, 'ownerInformation')}
                                placeholder="PLZ"
                            />
                            <Feedback errorMessages={errorMessages} fieldName={'ownerPostalCode'}/>
                        </Form.Group>
                    </div>
                    <Form.Group as={Col}>
                        <Form.Label>Ort</Form.Label>
                        <div className="input-margin-left">
                            <Form.Control
                                size="sm"
                                type="text"
                                name="ownerAddressLocality"
                                value={ownerInformation.ownerAddressLocality || ''}
                                onChange={handleChange.bind(this, 'ownerInformation')}
                                onBlur={trimValue.bind(this, 'ownerInformation')}
                                placeholder="Wohnort des Inhabers"
                            />
                        </div>
                        <Feedback errorMessages={errorMessages} fieldName={'ownerAddressLocality'}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Straße</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            name="ownerStreet"
                            value={ownerInformation.ownerStreet || ''}
                            onChange={handleChange.bind(this, 'ownerInformation')}
                            onBlur={trimValue.bind(this, 'ownerInformation')}
                            placeholder="Straße"
                        />
                        <Feedback errorMessages={errorMessages} fieldName={'ownerStreet'}/>
                    </Form.Group>
                    <div className="input-number input-margin-left input-margin-right">
                        <Form.Group as={Col}>
                            <Form.Label>Nr.</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                name="ownerHouseNumber"
                                value={ownerInformation.ownerHouseNumber || ''}
                                onChange={handleChange.bind(this, 'ownerInformation')}
                                onBlur={trimValue.bind(this, 'ownerInformation')}
                                placeholder="Nr."
                            />
                            <Feedback errorMessages={errorMessages} fieldName={'ownerHouseNumber'}/>
                        </Form.Group>
                    </div>
                </Form.Row>
                <Form.Group>
                    <Form.Label>Land</Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        name="ownerCountry"
                        value={country}
                        onChange={handleChange.bind(this, 'ownerInformation')}
                        onBlur={trimValue.bind(this, 'ownerInformation')}
                    >
                        <option>{dropdownDefaultValue}</option>
                        {countries.map((country: {ISO_3166_1: string, country: string, usages: string}, key) => (
                            <option key={key} value={country.ISO_3166_1}>{country.country}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Geburtstag</Form.Label>
                    <DatePicker
                        type="date"
                        name="ownerBirthday"
                        placeholderText="Geburtstag des Inhabers auswählen"
                        selected={ownerBirthday}
                        onChange={handleDateValueChange.bind(this, 'ownerInformation', 'ownerBirthday')}
                        locale={de}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                        shouldCloseOnSelect={true}
                        dateFormat="dd.MM.yyyy"
                        isClearable
                        autoComplete="off"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'ownerBirthday'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>E-Mail</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="ownerEmail"
                        value={ownerInformation.ownerEmail || ''}
                        onChange={handleChange.bind(this, 'ownerInformation')}
                        onBlur={trimValue.bind(this, 'ownerInformation')}
                        placeholder="E-Mail des Inhabers"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'ownerEmail'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Passwort</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="ownerEmailPassword"
                        value={ownerInformation.ownerEmailPassword || ''}
                        onChange={handleChange.bind(this, 'ownerInformation')}
                        onBlur={trimValue.bind(this, 'ownerInformation')}
                        placeholder="Passwort des Inhabers"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'ownerEmailPassword'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Sonstige Bemerkungen <p className="d-inline text-muted small">(max. 5000 Zeichen)</p></Form.Label>
                    <Form.Control
                        className="input-textarea-height"
                        size="sm"
                        as="textarea"
                        rows={4}
                        name="ownerAnnotation"
                        value={ownerInformation.ownerAnnotation || ''}
                        onChange={handleTextChange.bind(this, 'ownerInformation', 'ownerAnnotation')}
                        onBlur={trimTextValues.bind(this, 'ownerInformation', 'ownerAnnotation')}
                        placeholder="Sonstige Bemerkungen zum Inhaber"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'ownerAnnotation'}/>
                </Form.Group>
            </div>
        </>
    );
}
