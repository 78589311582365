// @flow

import React from 'react';
import Button from "react-bootstrap/Button";

const Forbidden = () => (
    <div className="forbidden">
        <h2>403 Forbidden</h2>
        <hr/>
        <div className="forbidden-footer">Keine Berechtigung für diese Ressource</div>
        <Button size="sm" variant="outline-dark" href="/">Zurück</Button>
    </div>
);

export default Forbidden;
