// @flow

import React from "react";
import {Form} from "react-bootstrap";
import Feedback from "../Feedback";

type PrivacyPolicyProps = {
    privacyPolicy: {
        privacyPolicy: null|string
    },
    handleTextChange: Function,
    trimTextValues: Function,
    errorMessages: [],
    inputValueReachedMaxLength: {},
}

export default function PrivacyPolicy({privacyPolicy, handleTextChange, trimTextValues, errorMessages, inputValueReachedMaxLength}: PrivacyPolicyProps) {
    if (privacyPolicy) {
        return (
            <>
                <div className="further-information-form-element">
                    <h5 className="form-part-title">Datenschutz</h5>
                    <Form.Group>
                        <Form.Label>Datenschutz <p className="d-inline text-muted small">(max. 65.000 Zeichen)</p>
                        </Form.Label>
                        <div className="furhter-information-input-textarea-height">
                            <Form.Control
                                isInvalid={inputValueReachedMaxLength.privacyPolicy}
                                size="sm"
                                as="textarea"
                                rows={12}
                                name="privacyPolicy"
                                value={privacyPolicy.privacyPolicy || ''}
                                onChange={handleTextChange.bind(this, 'privacyPolicy', 'privacyPolicy')}
                                onBlur={trimTextValues.bind(this, 'privacyPolicy', 'privacyPolicy')}
                                placeholder="Datenschutz des Addons"
                            />
                            <Form.Control.Feedback type="invalid">
                                Es können max. 65.000 Zeichen gespeichert werden!
                            </Form.Control.Feedback>
                            <Feedback errorMessages={errorMessages} fieldName={'privacyPolicy'}/>
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    }

    return null;
}
