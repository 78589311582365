// @flow

import React from "react";
import {Button} from "react-bootstrap";

type UserLineElementProps = {
    userId: string,
    id: string,
    email: string,
    name: string,
    userRole: string,
}

export default function ({userId, id, email, name, userRole}: UserLineElementProps) {
    return (
        <>
            <tr>
                <td>{id}</td>
                <td>{email}</td>
                <td>{name}</td>
                <td>{userRole}</td>
                <td>
                    <Button
                        type="submit"
                        variant="outline-dark"
                        size="sm"
                        href={"/user/edit/" + userId}
                    >
                        Edit
                    </Button>
                </td>
            </tr>
        </>
    );
}
