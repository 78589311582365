// @flow

export default function compareDatabaseAndFormValues(databaseAddon: Addon, formAddon: Addon): {} {
    let formParts = [];
    Object.keys(databaseAddon).map((formPart: string) => (
        formParts.push(formPart)
    ));

    let values = {};
    for (let i = 0; i < Object.keys(databaseAddon).length; i++) {

        if (formParts[i] === 'domainPathes' || formParts[i] === 'accessDatas') {
            values[formParts[i]] = databaseAddon[formParts[i]].map(function(dataRecord: string, key) {
                let dataRecordValues = {};
                dataRecordValues = Object.keys(dataRecord).map(function (dataRecordFieldName: string) {
                    if (typeof formAddon[formParts[i]] === 'undefined' || typeof formAddon[formParts[i]][key] === 'undefined' || typeof formAddon[formParts[i]][key][dataRecordFieldName] === 'undefined') {
                        dataRecordValues[dataRecordFieldName] = databaseAddon[formParts[i]][key][dataRecordFieldName];
                    }

                    if (typeof formAddon[formParts[i]] !== 'undefined' && typeof formAddon[formParts[i]][key] !== 'undefined' && typeof formAddon[formParts[i]][key][dataRecordFieldName] !== 'undefined') {
                        if (databaseAddon[formParts[i]][key][dataRecordFieldName] !== formAddon[formParts[i]][key][dataRecordFieldName]) {
                            dataRecordValues[dataRecordFieldName] = formAddon[formParts[i]][key][dataRecordFieldName];
                        }

                        if (databaseAddon[formParts[i]][key][dataRecordFieldName] === formAddon[formParts[i]][key][dataRecordFieldName]) {
                            dataRecordValues[dataRecordFieldName] = databaseAddon[formParts[i]][key][dataRecordFieldName];
                        }
                    }

                    return dataRecordValues;
                });

                return dataRecordValues[0];
            });
        }

        if (databaseAddon[formParts[i]] !== formAddon[formParts[i]]) {
            values[formParts[i]] = formAddon[formParts[i]];
        }

        if (databaseAddon[formParts[i]] === formAddon[formParts[i]] || typeof formAddon[formParts[i]] === "undefined") {
            values[formParts[i]] = databaseAddon[formParts[i]];
        }
    }

    return values;
}
