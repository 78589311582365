// @flow

import React, {useEffect, useState} from "react";
import {Card,} from "react-bootstrap";
import {createAddonApi, loadFilteredAddonsFromApi} from "../../Api/AddonApi";
import NotAuthorizedError from "../../Authorization/NotAuthorizedError";
import {loadDropdownValuesFromApi, loadTemplateFieldsFromApi} from "../../Api/DropdownApi";
import {formatDateValuesForFrontend} from "../Helper/DateFormatter";
import AddonListTable from "./AddonListTable";
import {CreateAddonButton} from "../Helper/Buttons";
import TemplateContainer from "./TemplateContainer";
import {changeTemplateApi, createTemplateApi, loadTemplatesApi, loadTemplateValuesApi} from "../../Api/TemplateApi";
import RedirectToEditAddon from "./RedirectToEditAddon";
import {formatLangugeValuesForFrontend, getFilteredLanguageOptions} from "../Helper/LanguageValueFormatter";
import {
    addonListSetDefaultTemplate,
    addonListXlsxExportApi,
    loadUserDefaultTemplate, loadUserDefaultTemplateId,
    resetAddonListDefaultTemplate
} from "../../Api/AddonListApi";
import {saveAs} from "file-saver";

export default function AddonList() {
    const [languages, setLanguages] = useState<null|[]>(null);
    const [templates, setTemplates] = useState<null|[]>(null);
    const [templateFields, setTemplateFields] = useState<null|[]>(null);
    const [hasCurrentTemplateChanged, setHasCurrentTemplateChanged] = useState<boolean>(false);
    const [isUrlReadOut, setIsUrlReadOut] = useState<boolean>(false);
    const [selectedTemplateFieldsWithValues, setSelectedTemplateFieldsWithValues] = useState<null|{}>(null);
    const [filteredLanguageOptions, setFilteredLanguageOptions] = useState<null|[{value: string, label: string}]>(null);
    const [filteredAddons, setFilteredAddons] = useState<null|FilteredAddon[]>(null);
    const [templateName, setTemplateName] = useState<null|string>(null);
    const [hasAnError, setHasAnError] = useState<null|boolean>(null);
    const [templateId, setTemplateId] = useState<string>('');
    const [defaultTemplateId, setDefaultTemplateId] = useState<null|string>(null);
    const [orderBy, setOrderBy] = useState<{key: string, order: string}>({key: 'consecutiveAddonId', order: 'desc'});
    const [addonId, setAddonId] = useState<null|string>(null);
    const [areAllAddonRecordsSelected, setAreAllAddonRecordsSelected] = useState<boolean>(false);
    const [xlsxExportData, setXlsxExportData] = useState<{addonIds: [], keys: []}>({addonIds: [], keys: []});

    useEffect(() => {
        if (!languages) {
            loadLanguages();
        }

        async function loadLanguages() {
            const response = await loadDropdownValuesFromApi('language');

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setLanguages(response.values);
        }
    }, [languages]);

    useEffect(() => {
        if (!templates) {
            loadTemplates();
        }

        async function loadTemplates() {
            const response = await loadTemplatesApi();

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setTemplates(response.templates);
        }
    }, [templates]);

    useEffect(() => {
        if (!templateFields) {
            loadTemplateFields();
        }

        async function loadTemplateFields(): void {
            const response = await loadTemplateFieldsFromApi();

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setTemplateFields(response.values);
        }
    }, [templateFields]);

    useEffect(() => {
        if (hasCurrentTemplateChanged && templateId) {
            doCompareCurrentAndEventTemplateFields();
        }

        async function doCompareCurrentAndEventTemplateFields() {
            setHasCurrentTemplateChanged(await compareCurrentAndEventTemplateFields());
        }

        async function compareCurrentAndEventTemplateFields(): boolean {
            const response = await loadTemplateValuesApi(templateId);

            let compareResults = [];

            if (Object.keys(response.template.templateKeys).length !== Object.keys(selectedTemplateFieldsWithValues).length) {
                compareResults.push(true);
            }

            if (response.template.templateName !== templateName) {
                compareResults.push(true);
            }

            compareResults.push(Object.keys(selectedTemplateFieldsWithValues).map((templateKey: string) => (
                !Object.keys(response.template.templateKeys).includes(templateKey)
            )));

            return compareResults.includes(true);
        }
    }, [hasCurrentTemplateChanged, selectedTemplateFieldsWithValues, templateId, templateName]);

    useEffect(() => {
        if (isUrlReadOut && selectedTemplateFieldsWithValues) {

            const filters = new URLSearchParams();

            if (templateId.length === 36) {
                filters.append('template', templateId);
            }

            if (Object.keys(selectedTemplateFieldsWithValues).length !== 0) {
                filters.append(orderBy.order, orderBy.key);
            }

            Object.keys(selectedTemplateFieldsWithValues).map((keyName: string) => (
                filters.append(keyName, selectedTemplateFieldsWithValues[keyName])
            ));

            const urlHash = window.location.hash.substr(1);
            if (filters.toString() !== urlHash) {
                window.location.hash = `${filters.toString()}`;
            }
        }

    }, [isUrlReadOut, orderBy, selectedTemplateFieldsWithValues, templateId]);

    useEffect(() => {
        if (templateName && templates) {
            let queryString = (window.location.hash).substr(1);

            if (queryString.slice(0, 8) === 'template') {
                const templateId = queryString.slice(9, 45);
                setTemplateId(templateId);
                setTemplateName(() => {
                    for (let i = 0; i < templates.length; i++) {
                        if (templates[i].templateId === templateId) {
                            return templates[i].templateName === templateName ? templates[i].templateName : templateName;
                        }
                    }
                });
            }
        }
    }, [templateName, templates]);

    useEffect(() => {
        if (templates && languages) {
            loadFilteredAddons();
        }

        async function loadFilteredAddons(): void {
            let queryString = (window.location.hash).substr(1);

            if (queryString.slice(0, 8) === 'template') {
                queryString = queryString.slice(46);
            }
            if (queryString.slice(0, 5) === 'desc') {
                const position = queryString.search('&');
                queryString    = queryString.slice(position);
            }

            if (queryString.slice(0, 4) === 'asc') {
                const position = queryString.search('&');
                queryString    = queryString.slice(position);
            }

            if (queryString.length > 0) {
                const response = await loadFilteredAddonsFromApi(queryString);

                if (response.status === 401) {
                    throw new NotAuthorizedError('Not authorized');
                }

                formatDateValuesForFrontend(response.addons);
                formatLangugeValuesForFrontend(response.addons, languages);

                setFilteredAddons(response.addons);
            }

            if (queryString.length === 0) {
                setFilteredAddons([]);
            }
        }
    }, [languages, selectedTemplateFieldsWithValues, templateFields, templates, orderBy]);

    useEffect(() => {
        if (languages && selectedTemplateFieldsWithValues && 'language' in selectedTemplateFieldsWithValues) {

            setFilteredLanguageOptions(
                getFilteredLanguageOptions(languages, selectedTemplateFieldsWithValues.language)
            );
        }
    }, [languages, selectedTemplateFieldsWithValues]);

    useEffect(() => {
        if (!isUrlReadOut && templates) {
            setValuesFromUrlParams();
        }

        async function setValuesFromUrlParams() {
            const response = await loadUserDefaultTemplateId();
            setDefaultTemplateId(response.templateId);

            let filterKeys = ((window.location.hash).substr(1)).split('&');
            let filters = {};

            for (let i = 0; i < filterKeys.length; i++) {
                let allocatePosition = filterKeys[i].indexOf('=');
                if (allocatePosition === -1) {
                    continue;
                }

                let key = filterKeys[i].substr(0, allocatePosition);
                filters[key] = decodeURIComponent(filterKeys[i].substr(allocatePosition + 1));
                filters[key] = filters[key].split('+').join(' ');
            }

            if (Object.keys(filters).length > 0) {
                // Wenn eine Template-ID in der URL steht, werden die ID und der zugehörige Name gesetzt und der Eintrag
                // dann aus den URL-Filtern entfernt
                if (filters.template) {
                    setTemplateId(filters.template);

                    for (let i = 0; i < templates.length; i++) {
                        if (templates[i].templateId === filters.template) {
                            setTemplateName(templates[i].templateName);
                        }
                    }

                    delete filters.template
                }

                if (filters.language) {
                    filters.language = filters.language.split(',');
                }

                if (filters['desc']) {
                    setOrderBy({
                        key: filters.desc,
                        order: 'desc'
                    });

                    delete filters.desc;
                }

                if (filters['asc']) {
                    setOrderBy({
                        key: filters.asc,
                        order: 'asc'
                    });

                    delete filters.asc;
                }
            }

            if (defaultTemplateId && Object.keys(filters).length === 0) {
                loadDefaultTemplate();
            }

            async function loadDefaultTemplate() {
                const response = await loadUserDefaultTemplate();

                if (response.status === 401) {
                    throw new NotAuthorizedError('Not authorized');
                }

                if (response.body === null) {
                    return;
                }

                setTemplateId(response.body.templateId);
                setTemplateName(response.body.templateName);
                setSelectedTemplateFieldsWithValues(response.body.templateKeys);
                setDefaultTemplateId(response.body.templateId);
            }

            setSelectedTemplateFieldsWithValues(filters);
            setIsUrlReadOut(true);
        }
    }, [defaultTemplateId, isUrlReadOut, templates]);

    useEffect(() => {
        if (areAllAddonRecordsSelected && filteredAddons) {
            setXlsxExportData({
                addonIds: filteredAddons.map((addon: FilteredAddon) => addon.addonId),
                keys: Object.keys(selectedTemplateFieldsWithValues).map((key: string) => key),
            });
        }

        if (!areAllAddonRecordsSelected && filteredAddons) {
            setXlsxExportData({
                addonIds: [],
                keys: [],
            });
        }
    }, [areAllAddonRecordsSelected, filteredAddons, selectedTemplateFieldsWithValues]);

    async function createAddon(event: SyntheticEvent<HTMLFormElement>) {
        event.preventDefault();

        const response = await createAddonApi();

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        setAddonId(response.addonId.addonId);
    }

    async function createTemplate(templateName: string, event: SyntheticEvent<HTMLFormElement>) {
        event.preventDefault();

        const response = await createTemplateApi(templateName, Object.keys(selectedTemplateFieldsWithValues));

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        if (response.status === 201) {
            setHasAnError(false);
        }

        if (response.status !== 201) {
            setHasAnError(true);
        }

        setTemplateId(response.body.templateId);

        setTemplates([
            ...templates,
            {
                'templateId': response.body.templateId,
                'templateName': response.body.templateName,
                'templateKeys': response.body.templateKeys,
            }
        ]);
    }

    async function changeTemplate(templateName: string, event: SyntheticInputEvent<HTMLButtonElement>): void {
        const response = await changeTemplateApi(
            event.target.value,
            templateName,
            Object.keys(selectedTemplateFieldsWithValues)
        );

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        if (response.status === 201) {
            setHasAnError(false);
        }

        if (response.status !== 201) {
            setHasAnError(true);
        }

        setHasCurrentTemplateChanged(false);
        setTemplates((templates) => {
            return templates.map((template: {}) => {
                if (template.templateId === response.body.templateId) {
                    return {
                        templateId: response.body.templateId,
                        templateName: response.body.templateName,
                        templateKeys: response.body.templateKeys,
                    }
                }

                return template;
            });
        });
    }

    async function useTemplate(event: SyntheticInputEvent<HTMLInputElement>): void {
        const templateId = event.target.value;

        const response = await loadTemplateValuesApi(templateId);

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        setTemplateId(templateId);
        setTemplateName(response.template.templateName);
        setSelectedTemplateFieldsWithValues(response.template.templateKeys);
        setHasAnError(null);
    }

    async function handleFilterChange(filterKey: string, event: SyntheticInputEvent<HTMLInputElement>): void {
        setSelectedTemplateFieldsWithValues({
            ...selectedTemplateFieldsWithValues,
            [filterKey]: event.target.value,
        });

        setHasAnError(null);
    }

    async function handleLanguageFilterChange(filterKey: string, event: ?[]): void {
        let languageFilters = [];

        if (event !== null) {
            event.map((filter: {value: string, label: string}) => (
                languageFilters.push(filter.value)
            ))
        }

        setSelectedTemplateFieldsWithValues({
            ...selectedTemplateFieldsWithValues,
            [filterKey]: [...languageFilters],
        });

        setHasAnError(null);
    }

    function handleTemplateNameChange(event: SyntheticInputEvent<HTMLInputElement>): void {
        if (event.target.value.length > 255) {
            return;
        }

        setTemplateName(event.target.value);
        setHasAnError(null);
        setHasCurrentTemplateChanged(true);
    }

    function selectTemplateField(event: SyntheticInputEvent<HTMLInputElement>): void {
        const fieldName = event.target.name;

        setTemplateFields({
            ...templateFields,
            [fieldName]: templateFields[fieldName] === false,
        });

        setSelectedTemplateFieldsWithValues((selectedTemplateFieldsWithValues => {
            if (templateFields[fieldName] === false) {
                return {
                    ...selectedTemplateFieldsWithValues,
                    [fieldName]: '',
                };
            }

            if (templateFields[fieldName] === true) {
                let memory = selectedTemplateFieldsWithValues;

                if (fieldName === 'language') {
                    setFilteredLanguageOptions(null);
                }

                delete memory[fieldName];

                return {...memory};
            }
        })(selectedTemplateFieldsWithValues));

        setHasAnError(null);

        if (templateId) {
            setHasCurrentTemplateChanged(true);
        }
    }

    function selectAddonRecord(event: SyntheticInputEvent<HTMLInputElement>): void {
        const value = event.target.name;

        if (value === 'all') {
            setAreAllAddonRecordsSelected(!areAllAddonRecordsSelected);
        }

        if (value !== 'all') {
            if (xlsxExportData.addonIds.includes(value)) {
                const oldIndex = (xlsxExportData.addonIds).indexOf(value);

                setXlsxExportData({
                    addonIds: [
                        ...xlsxExportData.addonIds.slice(0, oldIndex),
                        ...xlsxExportData.addonIds.slice(oldIndex + 1),
                    ],
                    keys: Object.keys(selectedTemplateFieldsWithValues).map((key: string) => {
                        return key;
                    }),
                });

                return;
            }

            setXlsxExportData({
                addonIds: [
                    ...xlsxExportData.addonIds,
                    value,
                ],
                keys: Object.keys(selectedTemplateFieldsWithValues).map((key: string) => {
                    return key;
                }),
            });
        }
    }

    async function addonListXlsxExport() {
        const response = await addonListXlsxExportApi(xlsxExportData);

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        if (response.status === 201) {
            let xlsxString = atob(response.xlsxFile.content);
            let arrayBuffer = new ArrayBuffer(xlsxString.length);
            let byteArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < xlsxString.length; i++) {
                byteArray[i] = xlsxString.charCodeAt(i) & 0xFF;
            }

            let file = new File([arrayBuffer], response.xlsxFile.name, {type: response.xlsxFile.type});

            saveAs(file);
        }
    }

    async function setDefaultTemplate() {
        if (templateId !== defaultTemplateId) {
            const result = await addonListSetDefaultTemplate(templateId);
            setTemplateId(result.body.body.templateId);
            setDefaultTemplateId(result.body.body.templateId);
        }

        if (templateId === defaultTemplateId) {
            await resetAddonListDefaultTemplate();
            setDefaultTemplateId(null);
        }
    }

    async function orderAddonListBy(filterKey: string, event: SyntheticInputEvent<HTMLInputElement>) {
        event.preventDefault()

        let order = 'asc';

        if (orderBy.key === filterKey && orderBy.order === 'asc') {
            order = 'desc';
        }

        setOrderBy({
            key: filterKey,
            order: order,
        });
    }

    return (
        <>
            <RedirectToEditAddon
                addonId={addonId}
                templateId={templateId}
                orderBy={orderBy}
                selectedTemplateFieldsWithValues={selectedTemplateFieldsWithValues}
            />
            <Card body="true">
                <div className="flex justify-content-between">
                    <Card.Title>Addon Liste</Card.Title>
                    <TemplateContainer
                        templates={templates}
                        templateId={templateId}
                        templateName={templateName}
                        hasCurrentTemplateChanged={hasCurrentTemplateChanged}
                        hasAnError={hasAnError}
                        defaultTemplateId={defaultTemplateId}
                        handleTemplateNameChange={handleTemplateNameChange}
                        createTemplate={createTemplate}
                        useTemplate={useTemplate}
                        changeTemplate={changeTemplate}
                        templateFields={templateFields}
                        selectedTemplateFieldsWithValues={selectedTemplateFieldsWithValues}
                        selectTemplateField={selectTemplateField}
                        setDefaultTemplate={setDefaultTemplate}
                    />
                    <CreateAddonButton createAddon={createAddon} />
                </div>
                <AddonListTable
                    filteredAddons={filteredAddons}
                    filteredLanguageOptions={filteredLanguageOptions}
                    selectedTemplateFieldsWithValues={selectedTemplateFieldsWithValues}
                    languages={languages}
                    xlsxExportData={xlsxExportData}
                    orderBy={orderBy}
                    templateId={templateId}
                    addonListXlsxExport={addonListXlsxExport}
                    selectAddonRecord={selectAddonRecord}
                    handleFilterChange={handleFilterChange}
                    handleLanguageFilterChange={handleLanguageFilterChange}
                    orderAddonListBy={orderAddonListBy}
                />
            </Card>
        </>
    );
}
