// @flow

import {formatDateForBackend} from "../../Helper/DateFormatter";
import {
    changeAddonAttachmentDataRecordValueApi,
    changeAddonDataRecordValueApi,
    changeAddonValueApi
} from "../../../Api/AddonApi";
import NotAuthorizedError from "../../../Authorization/NotAuthorizedError";

type ChangeAddonValue = {
    fieldName: string,
    errorMessage: string,
}

export const changeAddonValue = async (
    addonId: string,
    command: string,
    key: string,
    value: mixed,
): Promise<ChangeAddonValue> => {
    if (value === null || value.constructor.name === 'Date') {
        value = formatDateForBackend(value);
    }

    const response = await changeAddonValueApi(command, key, addonId, value);

    if (response.status === 401) {
        throw new NotAuthorizedError('Not authorized');
    }

    return {
        'body': response.body,
        'fieldName': key,
        'errorMessage': response.errorMessage,
    }
};

export const changeAddonDataRecordValue = async (
    addonId: string,
    command: string,
    DataRecordIdType: string,
    DataRecordId: string,
    key: string,
    value: string,
): Promise<ChangeAddonValue> => {
    const response = await changeAddonDataRecordValueApi(command, addonId, DataRecordIdType, DataRecordId, key, value);

    if (response.status === 401) {
        throw new NotAuthorizedError('Not authorized');
    }

    return {
        'fieldName': key,
        'errorMessage': response.errorMessage
    }
}

export const changeAddonAttachmentDataRecordValue = async (
    command: string,
    addonId: string,
    oldAttachmentId: string,
    newAttachmentName: string,
    oldAttachmentName: string,
    attachmentUploadDate: string,
): Promise<ChangeAddonValue> => {
    const response = await changeAddonAttachmentDataRecordValueApi(command, addonId, oldAttachmentId, newAttachmentName, oldAttachmentName, attachmentUploadDate);

    if (response.status === 401) {
        throw new NotAuthorizedError('Not authorized');
    }

    return {
        'fieldName': 'attachmentName',
        'errorMessage': response.errorMessage
    }
}
