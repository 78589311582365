// @flow

import React from "react";
import {Form} from "react-bootstrap";
import DatePicker from "react-datepicker/es";
import de from "date-fns/locale/de";
import Select from "react-select";
import {formatDateForDatePicker} from "../../Helper/DateFormatter";
import Feedback from "../Feedback";
import {
    formatCountryOptions,
    formatMobilePhoneOptions,
    formatSelectedCountries,
    formatSelectedNumber
} from "../Helper/ReactSelectOptionFormatter";

export default function GeneralInformation(
    {
        generalInformation,
        languages,
        countries,
        mobilePhones,
        handleChange,
        handleTextChange,
        handleDateValueChange,
        handlePlannedCountriesChange,
        handleMobilePhoneChange,
        trimValue,
        trimTextValues,
        errorMessages
    }: GeneralInformationProps
) {
    const dropdownDefaultValue = 'Bitte auswählen';

    if (!generalInformation || !mobilePhones) {
        return null;
    }

    let language = '';
    if (generalInformation.language !== null) {
        language = generalInformation.language;
    }

    let plannedLaunchDate = null;
    if (generalInformation.plannedLaunchDate !== null) {
        plannedLaunchDate = generalInformation.plannedLaunchDate;

        if (typeof generalInformation.plannedLaunchDate === "string") {
            plannedLaunchDate = formatDateForDatePicker(generalInformation.plannedLaunchDate);
        }
    }

    return (
        <>
            <div className="item-50">
                <h5 className="form-part-title">Allgemeine Informationen</h5>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="addonName"
                        value={generalInformation.addonName || ''}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                        placeholder="Name des Addons"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'addonName'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Beschreibung <p className="d-inline text-muted small">(max. 5000 Zeichen)</p></Form.Label>
                    <Form.Control
                        className="input-textarea-height"
                        size="sm"
                        as="textarea"
                        rows={4}
                        name="description"
                        value={generalInformation.description || ''}
                        onChange={handleTextChange.bind(this, 'generalInformation', 'description')}
                        onBlur={trimTextValues.bind(this, 'generalInformation', 'description')}
                        placeholder="Beschreibung des Addons"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'description'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Plattform</Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        name="platform"
                        value={generalInformation.platform || ''}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                    >
                        <option>{dropdownDefaultValue}</option>
                        <option>Google Chrome</option>
                        <option>Mozilla Firefox</option>
                    </Form.Control>
                    <Feedback errorMessages={errorMessages} fieldName={'platform'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Geplanter Start</Form.Label>
                    <DatePicker
                        type="date"
                        name="plannedLaunchDate"
                        placeholderText="Geplantes Startdatum auswählen"
                        selected={plannedLaunchDate}
                        onChange={handleDateValueChange.bind(this, 'generalInformation', 'plannedLaunchDate')}
                        locale={de}
                        shouldCloseOnSelect={true}
                        dateFormat="dd.MM.yyyy"
                        isClearable
                        autoComplete="off"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'plannedLaunchDate'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Sprache</Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        name="language"
                        value={language}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                    >
                        <option>{dropdownDefaultValue}</option>
                        {languages.map((language: {ISO_639_2: string, language: string}, key) => (
                            <option key={key} value={language.ISO_639_2}>{language.language}</option>
                        ))}
                    </Form.Control>
                    <Feedback errorMessages={errorMessages} fieldName={'language'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Geplante Verteilung in folgenden Ländern</Form.Label>
                    <Select
                        classNamePrefix="planned-countries"
                        name="languages"
                        placeholder="Mehrfachauswahl"
                        isMulti
                        value={formatSelectedCountries(generalInformation.plannedCountries, countries)}
                        formatOptionLabel={({ label, usages}) => (
                            <div className="planned-countries__option-label">
                                <div>{label}</div>
                                <div className="option-label__usages">{usages}</div>
                            </div>
                        )}
                        options={formatCountryOptions(countries)}
                        onChange={handlePlannedCountriesChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Entwickler</Form.Label>
                    <Form.Control
                        size="sm"
                        as="select"
                        name="developer"
                        value={generalInformation.developer || ''}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                    >
                        <option>{dropdownDefaultValue}</option>
                        <option>Hameln</option>
                        <option>Hannover</option>
                    </Form.Control>
                    <Feedback errorMessages={errorMessages} fieldName={'developer'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Store Publisher</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="storePublisher"
                        value={generalInformation.storePublisher || ''}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                        placeholder="Store Publisher des Addons"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'storePublisher'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Store Webmaster</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="storeWebmaster"
                        value={generalInformation.storeWebmaster || ''}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                        placeholder="Store Webmaster des Addons"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'storeWebmaster'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>App Name</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="appName"
                        value={generalInformation.appName || ''}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                        placeholder="App Name des Addons"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'appName'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Domain</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="domain"
                        value={generalInformation.domain || ''}
                        onChange={handleChange.bind(this, 'generalInformation')}
                        onBlur={trimValue.bind(this, 'generalInformation')}
                        placeholder="Domain des Addons"
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'domain'}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Zu verwendende Handynummer</Form.Label>
                    <Select
                        classNamePrefix="mobile-phones"
                        name="mobilePhone"
                        placeholder="Bitte auswählen"
                        hideSelectedOptions
                        value={formatSelectedNumber(generalInformation.mobilePhone.number, mobilePhones)}
                        formatOptionLabel={({ label, manufacturer, usages}) => (
                            <div className="mobile-phones__option-label">
                                <div className="option-label__manufacturer">{manufacturer}</div>
                                <div>{label}</div>
                                <div className="option-label__usages">{usages}</div>
                            </div>
                        )}
                        options={formatMobilePhoneOptions(mobilePhones)}
                        onChange={handleMobilePhoneChange}
                    />
                    <Feedback errorMessages={errorMessages} fieldName={'mobileNumber'}/>
                </Form.Group>
            </div>
        </>
    );
}
