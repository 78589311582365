// @flow

import {changeAddonAttachmentDataRecordValue, changeAddonDataRecordValue, changeAddonValue} from "./AddonValueChanger";

export default async function submitForm(addonId: string, formAddon: Addon, databaseAddon: Addon): [] {
    let responses = [];
    const actions = {
        generalInformation: {
            addonName: 'change_addon_name',
            description: 'change_addon_description',
            platform: 'change_addon_platform',
            plannedLaunchDate: 'change_addon_planned_launch_date',
            language: 'change_addon_language',
            plannedCountries: 'change_addon_planned_countries',
            developer: 'change_addon_developer',
            storePublisher: 'change_addon_store_publisher',
            storeWebmaster: 'change_addon_store_webmaster',
            appName: 'change_addon_app_name',
            domain: 'change_addon_domain',
            mobilePhone: {
                number: 'change_addon_mobile_number',
            },
        },
        ownerInformation: {
            ownerName: 'change_addon_owner_name',
            ownerNickname: 'change_addon_owner_nickname',
            ownerPostalCode: 'change_addon_owner_postal_code',
            ownerAddressLocality: 'change_addon_owner_address_locality',
            ownerStreet: 'change_addon_owner_street',
            ownerHouseNumber: 'change_addon_owner_house_number',
            ownerCountry: 'change_addon_owner_country',
            ownerBirthday: 'change_addon_owner_birthday',
            ownerEmail: 'change_addon_owner_email',
            ownerEmailPassword: 'change_addon_owner_email_password',
            ownerAnnotation: 'change_addon_owner_annotation',
        },
        domainPathes: {
            domainPathId: 'domainPathId',
            domainPath: 'change_addon_domain_path',
            domainPathDescription: 'change_addon_domain_path_description',
        },
        accessDatas: {
            accessDataId: 'accessDataId',
            accessDataTypeId: 'change_addon_access_data_type',
            accessDataName: 'change_addon_access_data_name',
            accessDataEmail: 'change_addon_access_data_email',
            accessDataEmailPassword: 'change_addon_access_data_email_password',
            accessDataAnnotation: 'change_addon_access_data_annotation',
        },
        attachments: {
            attachmentId: 'attachmentId',
            attachmentName: 'change_addon_attachment_name'
        },
        privacyPolicy: {
            privacyPolicy: 'change_addon_privacy_policy'
        },
        storeDescription: {
            storeDescription: 'change_addon_store_description'
        },
    };

    const formParts = Object.keys(actions);
    let formPart;
    for (let i = 0; i < formParts.length; i++) {
        formPart = formParts[i];
        const keys = Object.keys(actions[formPart]);
        let key;

        // Saves changed values of generalInformation, ownerInformation, privacyPolicy and storeDescription
        if (formPart !== 'accessDatas' && formPart !== 'domainPathes' && formPart !== 'attachments') {
            for(let j = 0; j < keys.length; j++) {
                key = keys[j];

                if (key === 'plannedCountries') {
                    if (databaseAddon[formPart][key].length !== formAddon[formPart][key].length) {
                        let countriesToSave = [];
                        formAddon.generalInformation.plannedCountries.map((country: string) => (
                            countriesToSave.push(country)
                        ));

                        responses.push(await changeAddonValue(addonId, actions[formPart][key], key, countriesToSave));
                    }

                    if (databaseAddon[formPart][key].length === formAddon[formPart][key].length) {
                        let differencesBetweenDatabaseAndForm = 0;
                        formAddon[formPart][key].map((country: string) => {
                            if (!databaseAddon.generalInformation.plannedCountries.includes(country)) {
                                differencesBetweenDatabaseAndForm = differencesBetweenDatabaseAndForm + 1;
                            }

                            return differencesBetweenDatabaseAndForm;
                        });

                        if (differencesBetweenDatabaseAndForm > 0) {
                            let countriesToSave = [];
                            formAddon.generalInformation.plannedCountries.map((country: string) => (
                                countriesToSave.push(country)
                            ));

                            responses.push(await changeAddonValue(addonId, actions[formPart][key], key, countriesToSave));
                        }
                    }
                }

                if (key === 'mobilePhone') {
                    let mobilePhoneKeys = Object.keys(actions[formPart][key]);
                    // eslint-disable-next-line no-loop-func
                    mobilePhoneKeys.map(async (mobilePhoneKey: string) => {
                        if(JSON.stringify(formAddon[formPart][key][mobilePhoneKey]) !== JSON.stringify(databaseAddon[formPart][key][mobilePhoneKey])) {
                            responses.push(await changeAddonValue(addonId, actions[formPart][key][mobilePhoneKey], mobilePhoneKey, formAddon[formPart][key][mobilePhoneKey]));
                        }
                    });
                }

                if (key !== 'plannedCountries' && key !== 'mobilePhone') {
                    if (JSON.stringify(formAddon[formPart][key]) !== JSON.stringify(databaseAddon[formPart][key])) {
                        responses.push(await changeAddonValue(addonId, actions[formPart][key], key, formAddon[formPart][key]));
                    }
                }
            }
        }

        // Saves changed values of domainPath- and accessData-records
        if (formPart === 'domainPathes' || formPart === 'accessDatas' || formPart === 'attachments') {
            let formRecord;
            let databaseRecord;
            let recordTypeId;

            for(let k = 0; k < formAddon[formPart].length; k++) {
                formRecord = formAddon[formPart][k];
                databaseRecord = databaseAddon[formPart][k];

                for (let j = 1; j < keys.length; j++) {
                    key = keys[j];
                    recordTypeId = keys[0];

                    if (formRecord[key] !== databaseRecord[key] && formPart !== 'attachments') {
                        responses.push(await changeAddonDataRecordValue(
                            addonId,
                            actions[formPart][key],
                            recordTypeId,
                            formRecord[recordTypeId],
                            key,
                            formRecord[key]
                        ));
                    }

                    if (formRecord[key] !== databaseRecord[key] && formPart === 'attachments') {
                        responses.push(await changeAddonAttachmentDataRecordValue(
                            actions[formPart][key],
                            addonId,
                            formRecord[recordTypeId],
                            formRecord[key],
                            databaseRecord[key],
                            databaseRecord['attachmentUploadDate']
                        ));
                    }
                }
            }
        }
    }

    return responses;
}
