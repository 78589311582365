// @flow

import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import NotAuthorizedErrorBoundary from "./Authorization/NotAuthorizedErrorBoundary";
import Navigation from "./Layout/Navigation";
import {aboutMeApi} from "./Api/AuthorizationApi";

type Props = {
    exact: boolean,
    path: string,
    component: React$StatelessFunctionalComponent<{}>,
    roles: string,
}

export default function PrivateRoute(props:Props) {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean|null>(null);
    const [hasPermission, setHasPermission] = useState<boolean|null>(null);
    const [role, setRole] = useState<string>('');

    // Effect hook with an async function instead of building a class component with state
    useEffect(() => {
        if (isLoggedIn === null) {
            proofLogin();
        }

        async function proofLogin() {
            const response = await aboutMeApi();

            if (response.status === 200) {
                setIsLoggedIn(true);
                setRole(response.role);
            }

            if (response.status === 401) {
                setIsLoggedIn(false);
                setHasPermission(false);
            }
        }

        if (isLoggedIn === true) {
            proofPermission(role);
        }

        function proofPermission(role: string) {
            setHasPermission(false);

            if (props.roles.includes(role)) {
                setHasPermission(true);
            }
        }
    }, [hasPermission, isLoggedIn, props.roles, role]);

    if (isLoggedIn === null) {
        return null;
    }

    if (isLoggedIn) {
        if (hasPermission === null) {
            return null;
        }

        if (hasPermission && props.exact) {
            return(
                // Catches thrown 'NotAuthorizedErrors' of API calls in the given component.
                <NotAuthorizedErrorBoundary>
                    <Navigation role={role}/>
                    <Route exact={props.exact} path={props.path} component={props.component} />
                </NotAuthorizedErrorBoundary>
            );
        }

        if (hasPermission && !props.exact) {
            return(
                // Catches thrown 'NotAuthorizedErrors' of API calls in the given component.
                <NotAuthorizedErrorBoundary>
                    <Route component={props.component} />
                </NotAuthorizedErrorBoundary>
            );
        }

        return (
            <Redirect to="/forbidden" />
        );
    }

    return (
        <Redirect to="/login" />
    );
};
