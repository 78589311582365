// @flow

import React from "react";

type ResultMessageProps = {
    hasSucceeded: boolean,
    hasAnError: boolean,
    addonName: null|string,
    elapsedSeconds: number,
    elapsedMinutes: number,
}

export default function AddonFormResultMessage(
    {
        hasSucceeded,
        hasAnError,
        addonName,
        elapsedSeconds,
        elapsedMinutes
    }: ResultMessageProps
) {
    if (!hasSucceeded && hasAnError) {
        return <span className="font-color-white mt-4">Speichern von {addonName} fehlgeschlagen</span>;
    }

    if (hasSucceeded && !hasAnError) {
        if (elapsedSeconds > 0 && elapsedSeconds <= 59) {

            let timeValue = 'Sekunde';

            if (elapsedSeconds > 1) {
                timeValue = timeValue + 'n';
            }

            return <span
                className="font-color-white mt-4">Zuletzt vor {elapsedSeconds} {timeValue} gespeichert</span>;
        }

        if (elapsedMinutes > 0) {
            let timeValue = 'Minute';

            if (elapsedMinutes > 1) {
                timeValue = timeValue + 'n';
            }

            return <span className="font-color-white mt-4">Zuletzt vor {elapsedMinutes} {timeValue} gespeichert</span>;
        }
    }

    return <span className="font-color-white mt-4" />;
}
