// @flow

import React, {useState} from "react";
import {Alert, Button, Form, Modal, Table} from "react-bootstrap";
import {CloudDownload} from "react-bootstrap-icons";

type AttachmentsProps = {
    formAddon: Addon,
    downloadAttachment: Function,
    deleteAttachment: Function,
    uploadAttachments: Function,
    inputValueReachedMaxLength: {},
    isCurrentUploading: $JSXIntrinsics,
    invalidFiles: [],
    closeUploadAlert: Function,
    handleDataRecordValueChange: Function,
    trimDataRecordValue: Function
}

export default function Attachments(
    {
        formAddon,
        downloadAttachment,
        deleteAttachment,
        uploadAttachments,
        inputValueReachedMaxLength,
        isCurrentUploading,
        invalidFiles,
        closeUploadAlert,
        handleDataRecordValueChange,
        trimDataRecordValue
    }: AttachmentsProps
) {
    return (
        <>
            <div className="item-100 attachment-form">
                <div className="flex justify-content-between">
                    <h5 className="form-part-title">Anhänge</h5>
                </div>
                <Table>
                    <thead>
                    <tr>
                        <th>Dateiname</th>
                        <th>hochgeladen von</th>
                        <th>hochgeladen am</th>
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    <AttachmentLineElements
                        attachments={formAddon.attachments}
                        downloadAttachment={downloadAttachment}
                        deleteAttachment={deleteAttachment}
                        handleDataRecordValueChange={handleDataRecordValueChange}
                        trimDataRecordValue={trimDataRecordValue}
                    />
                    </tbody>
                </Table>
                <div className="upload-attachment">
                    <Form.Group>
                        <Form.File
                            isInvalid={inputValueReachedMaxLength.fileUpload}
                            name="fileUpload"
                            label={isCurrentUploading}
                            data-browse="Datei hochladen"
                            onChange={uploadAttachments.bind(this, 'fileUpload')}
                            onClick={(event) => event.target.value = null}
                            multiple
                            custom
                        />
                    </Form.Group>
                    <p className="text-muted small">Maximal 100 MB</p>
                </div>
                <TooLargeFiles invalidFiles={invalidFiles} closeUploadAlert={closeUploadAlert} />
            </div>
        </>
    );
}

type AttachmentLineElementsProps = {
    attachments: [],
    downloadAttachment: Function,
    deleteAttachment: Function,
    handleAttachmentNameChange: Function,
    trimDataRecordValue: Function,
}

function AttachmentLineElements({
    attachments,
    downloadAttachment,
    deleteAttachment,
    handleDataRecordValueChange,
    trimDataRecordValue
}: AttachmentLineElementsProps) {
    if (attachments) {
        if (attachments.length > 0) {
            const lastAttachment = attachments.length - 1;

            let splittedDate = attachments[lastAttachment].attachmentUploadDate.split(' ');
            let date = splittedDate[0].split('-');

            if (typeof date[2] === "string") {
                date = date[2] + '.' + date[1] + '.' + date[0];
            }

            attachments[lastAttachment].attachmentUploadDate = date + ' ' + splittedDate[1];
        }

        return (
            attachments.map((
                attachment:
                    {
                        attachmentId: string,
                        attachmentName: string,
                        attachmentUploader: string,
                        attachmentUploadDate: string,
                    },
                key,
            ) => (
                <AttachmentElement
                    key={key}
                    index={key}
                    attachmentId={attachment.attachmentId}
                    attachmentName={attachment.attachmentName}
                    attachmentUploader={attachment.attachmentUploader}
                    attachmentUploadDate={attachment.attachmentUploadDate}
                    downloadAttachment={downloadAttachment}
                    deleteAttachment={deleteAttachment}
                    handleDataRecordValueChange={handleDataRecordValueChange}
                    trimDataRecordValue={trimDataRecordValue}
                />
            ))
        );
    }

    return null;
}

type AttachmentElementProps = {
    attachmentId: string,
    index: number,
    attachmentName: string,
    attachmentUploader: string,
    attachmentUploadDate: string,
    downloadAttachment: Function,
    deleteAttachment: Function,
    handleDataRecordValueChange: Function,
    trimDataRecordValue: Function,
}

function AttachmentElement(
    {
        attachmentId,
        index,
        attachmentName,
        attachmentUploader,
        attachmentUploadDate,
        downloadAttachment,
        deleteAttachment,
        handleDataRecordValueChange,
        trimDataRecordValue
    }: AttachmentElementProps
) {
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    return (
        <>
            <tr>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        name="attachmentName"
                        value={attachmentName || ''}
                        onChange={handleDataRecordValueChange.bind(this, 'attachments', index)}
                        onBlur={trimDataRecordValue.bind(this, 'attachments', index)}
                        placeholder="App Name des Addons"
                    />
                </td>
                <td>{attachmentUploader}</td>
                <td>{attachmentUploadDate}</td>
                <td className="flex delete-attachment">
                    <Button
                        size="sm"
                        type="button"
                        variant="outline-primary"
                        name="downloadAttachment"
                        title="Anhang Downloaden"
                        onClick={downloadAttachment.bind(this, attachmentName)}
                    >
                        <CloudDownload size={10}/>
                    </Button>
                    <Button
                        size="sm"
                        type="button"
                        name="deleteAttachment"
                        onClick={() => setShowConfirmDialog(true)}
                        title="Anhang löschen"
                        variant="outline-danger"
                        >–</Button>
                </td>
                <Modal show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Möchten Sie den Anhang {attachmentName} wirklich löschen?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmDialog(false)}>Abbrechen</Button>
                        <Button variant="danger" onClick={() => deleteAttachmentAndHideDialog()}>Löschen</Button>
                    </Modal.Footer>
                </Modal>
            </tr>
        </>
    );

    function deleteAttachmentAndHideDialog() {
        deleteAttachment(attachmentId, attachmentName, index);
        setShowConfirmDialog(false);
    }
}

type TooLargeFilesProps = {
    invalidFiles: [],
    closeUploadAlert: Function,
}

function TooLargeFiles({invalidFiles, closeUploadAlert}: TooLargeFilesProps) {
    if (invalidFiles.length > 0) {
        return (
            <>
                <div className="flex justify-content-between align-items-center file-upload-alert">
                    <span className="text-muted">Folgende Dateien sind zu groß für den Upload bzw. leer:</span>
                    <Button variant="outline-dark" size="sm" onClick={closeUploadAlert}>
                        OK
                    </Button>
                </div>
                {invalidFiles.map((fileName: string, key) => (
                    <div key={key} className="file-upload-alert">
                        <Alert variant="danger" closeLabel>{fileName}</Alert>
                    </div>
                ))}

            </>
        );
    }

    return null;
}