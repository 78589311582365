// @flow

import React from "react";
import {Redirect} from "react-router-dom";

type RedirectProps = {
    addonId: string,
    templateId: string,
    orderBy: {},
    selectedTemplateFieldsWithValues: {},
}

export default function RedirectToEditAddon({addonId, orderBy, selectedTemplateFieldsWithValues, templateId}: RedirectProps) {
    if (addonId) {
        return <Redirect
            to={{
                pathname: `/addon/edit/${addonId}`,
                state: {
                    templateId: templateId,
                    orderBy: orderBy,
                    selectedTemplateFieldsWithValues: selectedTemplateFieldsWithValues
                }
            }}
        />
    }

    return null;
}
