// @flow

import React from "react";
import {Card} from "react-bootstrap";

export default function () {
    return(
        <Card body="true">
            <Card.Title>Dashboard</Card.Title>
        </Card>
    );
}
