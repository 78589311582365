// @flow

import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {CreateRecordButton, DeleteRecordButton} from "../../Helper/Buttons";
import Feedback from "../Feedback";

type DomainPathesProps = {
    formAddon: Addon,
    createDomainPathElement: Function,
    deleteDomainPathElement: Function,
    handleDataRecordValueChange: Function,
    trimDataRecordValue: Function,
    errorMessages: [],
}

export default function DomainPathes(
    {
        formAddon,
        createDomainPathElement,
        deleteDomainPathElement,
        handleDataRecordValueChange,
        trimDataRecordValue,
        errorMessages
    }: DomainPathesProps
) {
    return (
        <>
            <div className="item-100">
                <div className="flex justify-content-between">
                    <h5 className="form-part-title">Domain / Pfade</h5>
                </div>
                <DomainPathLabels />
                <DomainPathElements
                    domainPathes={formAddon.domainPathes}
                    deleteDomainPathElement={deleteDomainPathElement}
                    handleDataRecordValueChange={handleDataRecordValueChange}
                    trimDataRecordValue={trimDataRecordValue}
                    errorMessages={errorMessages}
                />
                <CreateRecordButton
                    name="addDomainPathRecord"
                    title="Domain / Pfad"
                    createFunction={createDomainPathElement}
                />
            </div>
        </>
    );
}

function DomainPathLabels() {
    return(
        <>
            <Form.Row>
                <div className="flex justify-content-between access-data-form">
                    <div className="domain-path-form-element">
                        <Form.Group>
                            <Form.Label>Pfad</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="domain-path-form-element">
                        <Form.Group>
                            <Form.Label>Beschreibung</Form.Label>
                        </Form.Group>
                    </div>
                    <div className="domain-path-form-button"/>
                </div>
            </Form.Row>
        </>
    );
}

type DomainPathElementsProps = {
    domainPathes: null|[],
    deleteDomainPathElement: Function,
    handleDataRecordValueChange: Function,
    trimDataRecordValue: Function,
    errorMessages: Function,
}

function DomainPathElements(
    {domainPathes,
        deleteDomainPathElement,
        handleDataRecordValueChange,
        trimDataRecordValue,
        errorMessages
    }: DomainPathElementsProps
) {
    if (domainPathes) {
        return(
            domainPathes.map((
                domainPath:
                    {
                        domainPathId: string,
                        domainPath: string,
                        domainPathDescription: string,
                    },
                key
            ) => (
                <DomainPathElement
                    key={domainPath.domainPathId}
                    domainPathId={domainPath.domainPathId}
                    index={key}
                    domainPath={domainPath.domainPath}
                    domainPathDescription={domainPath.domainPathDescription}
                    deleteDomainPathElement={deleteDomainPathElement}
                    handleDataRecordValueChange={handleDataRecordValueChange}
                    trimDataRecordValue={trimDataRecordValue}
                    errorMessages={errorMessages}
                />
            ))
        );
    }

    return null;
}

type DomainPathElementProps = {
    index: number,
    domainPath: string,
    domainPathDescription: string,
    deleteDomainPathElement: Function,
    handleDataRecordValueChange: Function,
    trimDataRecordValue: Function,
    errorMessages: [],
}

function DomainPathElement(
    {
        index,
        domainPath,
        domainPathDescription,
        deleteDomainPathElement,
        handleDataRecordValueChange,
        trimDataRecordValue,
        errorMessages
    }: DomainPathElementProps
) {
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    return (
        <>
            <Form.Row>
                <div className="flex justify-content-between domain-path-form">
                    <div className="domain-path-form-element">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                name="domainPath"
                                value={domainPath || ''}
                                onChange={handleDataRecordValueChange.bind(this, 'domainPathes', index)}
                                onBlur={trimDataRecordValue.bind(this, 'domainPathes', index)}
                                placeholder="Pfad"
                            />
                            <Feedback errorMessages={errorMessages} fieldName={'domainPath' + index}/>
                        </Form.Group>
                    </div>
                    <div className="domain-path-form-element">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                name="domainPathDescription"
                                maxLength={5000}
                                value={domainPathDescription || ''}
                                onChange={handleDataRecordValueChange.bind(this, 'domainPathes', index)}
                                onBlur={trimDataRecordValue.bind(this, 'domainPathes', index)}
                                placeholder="Beschreibung"
                            />
                            <Feedback errorMessages={errorMessages} fieldName={'domainPathDescription' + index}/>
                        </Form.Group>
                    </div>
                    <DeleteRecordButton
                        name="deleteDomainPathElement"
                        className="domain-path-form-button"
                        setShowConfirmDialog={setShowConfirmDialog}
                        index={null}
                    />
                </div>
                <Modal show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Möchten Sie diese Domain wirklich löschen?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowConfirmDialog(false)}
                        >Abbrechen</Button>
                        <Button
                            variant="danger"
                            onClick={() => deleteDomainPathElementAndHideDialog()}
                        >Löschen</Button>
                    </Modal.Footer>
                </Modal>
            </Form.Row>
        </>
    );

    function deleteDomainPathElementAndHideDialog() {
        deleteDomainPathElement(index);
        setShowConfirmDialog(false);
    }
}
