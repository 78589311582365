// @flow

import React from "react";
import {Redirect} from "react-router-dom";
import NotAuthorizedError from "./NotAuthorizedError";

export default class NotAuthorizedErrorBoundary extends React.Component<{}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: error instanceof NotAuthorizedError };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            return (
                <Redirect to="/login" />
            );
        }

        return this.props.children;
    }
}
