// @flow

import React from "react";
import {Button, Card, Form, Table} from "react-bootstrap";
import {PencilSquare, SortDown, SortDownAlt} from 'react-bootstrap-icons';
import {getColumnName, getLanguageFilterOptions, getPlaceholder} from "./AddonListHelper";
import Select from "react-select";
import {XlsxExportButton} from "../Helper/Buttons";
import {Link} from "react-router-dom";

type AddonListTableProps = {
    filteredAddons: null|{}[],
    filteredLanguageOptions: null|{}[],
    selectedTemplateFieldsWithValues: null|{},
    languages: null|[],
    xlsxExportData: {
        addonIds: [],
        keys: [],
    },
    orderBy: {
        key: string,
        order: string,
    },
    templateId: string,
    addonListXlsxExport: Function,
    selectAddonRecord: Function,
    handleFilterChange: Function,
    handleLanguageFilterChange: Function,
    orderAddonListBy: Function,
}

export default function AddonListTable(
    {
        filteredAddons,
        filteredLanguageOptions,
        selectedTemplateFieldsWithValues,
        languages,
        xlsxExportData,
        orderBy,
        templateId,
        addonListXlsxExport,
        selectAddonRecord,
        handleFilterChange,
        handleLanguageFilterChange,
        orderAddonListBy
    }: AddonListTableProps
) {
    if (!selectedTemplateFieldsWithValues || !filteredAddons) {
        return null;
    }

    return (
        <>
            <Card className="xlsx-export-buttons height-600 overflow-auto mt-3">
                <Table className="w-100">
                    <TableHeader
                        filteredLanguageOptions={filteredLanguageOptions}
                        selectedTemplateFieldsWithValues={selectedTemplateFieldsWithValues}
                        languages={languages}
                        orderBy={orderBy}
                        handleFilterChange={handleFilterChange}
                        handleLanguageFilterChange={handleLanguageFilterChange}
                        selectAddonRecord={selectAddonRecord}
                        orderAddonListBy={orderAddonListBy}
                    />
                    <TableBody
                        templateId={templateId}
                        orderBy={orderBy}
                        selectedTemplateFieldsWithValues={selectedTemplateFieldsWithValues}
                        filteredAddons={filteredAddons}
                        xlsxExportData={xlsxExportData}
                        selectAddonRecord={selectAddonRecord}
                    />
                </Table>
                <XlsxExportButton
                    xlsxExportDataAddonIds={xlsxExportData.addonIds}
                    addonListXlsxExport={addonListXlsxExport}
                />
            </Card>
        </>
    );
}

type TableHeaderProps = {
    filteredLanguageOptions: {}[],
    selectedTemplateFieldsWithValues: {},
    languages: null|[],
    orderBy: {
        key: string,
        order: string,
    },
    handleFilterChange: Function,
    handleLanguageFilterChange: Function,
    selectAddonRecord: Function,
    orderAddonListBy: Function,
}

function TableHeader(
    {
        filteredLanguageOptions,
        selectedTemplateFieldsWithValues,
        languages,
        orderBy,
        handleFilterChange,
        handleLanguageFilterChange,
        selectAddonRecord,
        orderAddonListBy
    }: TableHeaderProps
) {
    return (
        <>
            <thead>
                <tr>
                    <th className="xlsx-export-checkbox-all">
                        <Form.Check
                            id="all"
                            name="all"
                            onChange={selectAddonRecord}
                        />
                    </th>
                    <th className="edit-addon-button" />
                    {Object.keys(selectedTemplateFieldsWithValues).map((filterKey: string, key) => {
                        if (filterKey === 'language' && languages) {
                            return (
                                <th key={key} className="addon-list-cell__react-select">
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>
                                                <Button
                                                    size="sm"
                                                    variant="light"
                                                    disabled={true}
                                                    className="addon-list-header-cell"
                                                >
                                                    {getColumnName(filterKey)}
                                                </Button>
                                            </Form.Label>
                                            <Select
                                                classNamePrefix="react-select"
                                                name="languages"
                                                placeholder="Mehrfachauswahl"
                                                isMulti
                                                defaultValue={filteredLanguageOptions}
                                                options={getLanguageFilterOptions(languages)}
                                                onChange={handleLanguageFilterChange.bind(this, filterKey)}
                                            />
                                        </Form.Group>
                                    </Form>
                                </th>
                            );
                        }

                        function sortSymbol() {
                            if (orderBy.key === filterKey && orderBy.order === 'desc') {
                                return (
                                    <SortDown color="black" size={18}/>
                                );
                            }

                            if (orderBy.key === filterKey && orderBy.order === 'asc') {
                                return (
                                    <SortDownAlt color="black" size={18}/>
                                );
                            }
                        }

                        let className = 'addon-list-cell';

                        if (filterKey === 'consecutiveAddonId') {
                            className = 'addon-list-cell__consecutive-addon-id'
                        }

                        return (
                            <th key={key} className={className}>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>
                                            <Button
                                                size="sm"
                                                variant="light"
                                                className="addon-list-header-cell"
                                                value={filterKey}
                                                onClick={e => orderAddonListBy(filterKey, e)}
                                            >
                                                {getColumnName(filterKey)}
                                                {sortSymbol()}
                                            </Button>
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name={filterKey}
                                            value={selectedTemplateFieldsWithValues[filterKey]}
                                            onChange={handleFilterChange.bind(this, filterKey)}
                                            placeholder={getPlaceholder(filterKey)}
                                        />
                                    </Form.Group>
                                </Form>
                            </th>
                        );
                    })}
                </tr>
            </thead>
        </>
    );
}

type TableBodyProps = {
    filteredAddons: {}[],
    xlsxExportData: {
        addonIds: [],
        keys: [],
    },
    templateId: string,
    orderBy: {
        key: string,
        order: string,
    },
    selectedTemplateFieldsWithValues: {},
    selectAddonRecord: Function,
}

function TableBody({
       filteredAddons,
       xlsxExportData,
       templateId, orderBy,
       selectedTemplateFieldsWithValues,
       selectAddonRecord
}: TableBodyProps) {
    let addonValues = [];

    return (
        <tbody>
        {filteredAddons.map((filteredAddon: FilteredAddon, key) => (
            <AddonLineElement
                key={key}
                templateId={templateId}
                orderBy={orderBy}
                selectedTemplateFieldsWithValues={selectedTemplateFieldsWithValues}
                xlsxExportData={xlsxExportData}
                selectAddonRecord={selectAddonRecord}
                addonValues={Object.keys(filteredAddon).map((keyName: string) => (
                    addonValues[keyName] = filteredAddon[keyName]
                ))}
            />
        ))}
        </tbody>
    );
}

type AddonLineElementProps = {
    xlsxExportData: {
        addonIds: [],
        keys: [],
    },
    templateId: string,
    orderBy: {
        key: string,
        order: string,
    },
    selectedTemplateFieldsWithValues: {},
    selectAddonRecord: Function,
    addonValues: [],
}

function AddonLineElement({
        xlsxExportData,
        templateId,
        orderBy,
        selectedTemplateFieldsWithValues,
        selectAddonRecord,
        addonValues
    }: AddonLineElementProps) {
    const defaultValue = <i className="text-muted">k.A.</i>;
    const addonListValues = addonValues.slice(1);

    return(
        <>
            <tr>
                <td className="xlsx-export-checkbox">
                    <Form.Check
                        key={addonListValues[0]}
                        id={addonValues[0]}
                        name={addonValues[0]}
                        checked={xlsxExportData.addonIds.includes(addonValues[0])}
                        onChange={selectAddonRecord}
                    />
                </td>
                <td className="edit-addon-button">
                    <Link
                        title="Addon bearbeiten"
                        to={{
                            pathname: `/addon/edit/${addonValues[0]}`,
                            state: {
                                templateId: templateId,
                                orderBy: orderBy,
                                selectedTemplateFieldsWithValues: selectedTemplateFieldsWithValues
                            }
                        }}
                    >
                        <PencilSquare color="green" size={20}/>
                    </Link>
                </td>
                {addonListValues.map((addonValue: string, key) => (
                    <td key={key}>{addonValue || defaultValue}</td>
                ))}
            </tr>
        </>
    );
}
