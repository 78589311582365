// @flow

export type AboutMeResponse = {
    role: string,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export async function aboutMeApi(): Promise<AboutMeResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/me', {
            credentials: 'include',
            method: 'GET',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            userIdentifier: data.body.userIdentifier,
            role: data.body.role,
            error: false,
            errorMessage: response.errorMessage,
            status: response.status,
        }
    } catch (e) {
        return {
            role: '',
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        };
    }
}
