// @flow

import React, {useEffect, useState} from "react";
import {Button, Card, Form, Spinner} from "react-bootstrap";
import NProgress from "nprogress";
import GeneralInformation from "./FormParts/GeneralInformation";
import OwnerInformation from "./FormParts/OwnerInformation";
import PrivacyPolicy from "./FormParts/PrivacyPolicy";
import StoreDescription from "./FormParts/StoreDescription";
import {BackButton, SubmitButton} from "../Helper/Buttons";
import AccessDatas from "./FormParts/AccessData/AccessDatas";
import DomainPathes from "./FormParts/DomainPathes";
import {loadSingleAddonFromApi} from "../../Api/AddonApi";
import NotAuthorizedError from "../../Authorization/NotAuthorizedError";
import compareDatabaseAndFormValues from "./Helper/compareDatabaseAndFormValues";
import {formatAttachmentDateValue} from "../Helper/DateFormatter";
import formatBitNumber from "./Helper/formatBitNumber";
import uploadFile from "./Helper/fileUpload";
import {deleteAttachmentApi, downloadAttachmentApi} from "../../Api/AttachmentApi";
import {Redirect} from "react-router-dom";
import {saveAs} from "file-saver";
import submitForm from "./Helper/submitForm";
import {loadDropdownValuesFromApi} from "../../Api/DropdownApi";
import AddonFormResultMessage from "./AddonFormResultMessage";
import {changeAddonValue} from "./Helper/AddonValueChanger";
import {createAccessDataTypeApi, getAccessDataTypeIdByName} from "../../Api/AccessDataApi";
import Attachments from "./FormParts/Attachments";
import {useLocation} from "react-router-dom";

type AddonFormProps = {
    match: {
        params: {
            addonId: string,
        },
        location: {
            state: {},
        }
    },
}

type ChangeAddonValue = {
    fieldName: string,
    errorMessage: string,
}

export default function AddonForm({match}: AddonFormProps) {
    // State to navigate with buttons
    const [backToList, setBackToList] = useState<boolean>(false);
    // State to keep, compare and save changing values
    const dropdownDefaultValue = 'Bitte auswählen';
    const addonId = match.params.addonId;
    const [languages, setLanguages] = useState<null|[]>(null);
    const [countries, setCountries] = useState<null|[]>(null);
    const [mobilePhones, setMobilePhones] = useState<null|[]>(null);
    const [addonLoaded, setAddonLoaded] = useState<boolean>(false);
    const [isDatabaseAddonSetted, setIsDatabaseAddonSetted] = useState<boolean>(false);
    const [isFormAddonSetted, setIsFormAddonSetted] = useState<boolean>(false);
    const [databaseAddon, setDatabaseAddon] = useState<Addon>({});
    const [formAddon, setFormAddon] = useState<Addon>({});
    const [addonName, setAddonName] = useState<string>('');
    const [isCurrentUploading, setIsCurrentUploading] = useState<$JSXIntrinsics>(<span />);
    const [invalidFiles, setInvalidFiles] = useState<[]>([]);
    const [inputValueReachedMaxLength, setInputValueReachedMaxLength] = useState<{}>({});
    // State to handle the result message after submitting the form
    const [hasSucceeded, setHasSucceeded] = useState<boolean>(false);
    const [hasAnError, setHasAnError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<[]>([]);
    const [timer, setTimer] = useState<null|number>(null);
    const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);
    const [elapsedMinutes, setElapsedMinutes] = useState<number>(0);
    const [redirectInformation, setRedirectInformation] = useState<null|string>((useLocation()).state || null)

    useEffect(() => {
        if (!languages) {
            loadLanguages();
        }

        async function loadLanguages() {
            const response = await loadDropdownValuesFromApi('language');

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setLanguages(response.values);
        }
    }, [languages]);

    useEffect(() => {
        if (!countries || !addonLoaded) {
            loadCountries();
        }

        async function loadCountries() {
            const response = await loadDropdownValuesFromApi('country');

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setCountries(response.values)
        }
    }, [addonLoaded, countries]);

    useEffect(() => {
        if (!mobilePhones || !addonLoaded) {
            loadMobilePhones();
        }

        async function loadMobilePhones() {
            const response = await loadDropdownValuesFromApi('mobilePhone');

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setMobilePhones(response.values);
        }
    }, [addonLoaded, mobilePhones]);

    useEffect(() => {
        if (!addonLoaded && languages && countries) {
            loadAddon();
        }

        async function loadAddon() {
            const response = await loadSingleAddonFromApi(addonId);

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setDatabaseAddon(response.addon);
            setAddonName(response.addon.generalInformation.addonName);

            setAddonLoaded(true);
        }

        if (!isDatabaseAddonSetted && addonLoaded) {
            if (databaseAddon.attachments.length > 0) {
                setDatabaseAddon(databaseAddon => {
                    let attachments = [];

                    for (let i = 0; i < databaseAddon.attachments.length; i++) {
                        attachments.push({
                            ...databaseAddon.attachments[i],
                            attachmentUploadDate: formatAttachmentDateValue(databaseAddon.attachments[i].attachmentUploadDate)
                        });
                    }

                    return {
                        ...databaseAddon,
                        attachments: [
                            ...attachments,
                        ],
                    };
                });
            }

            setIsDatabaseAddonSetted(true);
        }

        if (!isFormAddonSetted && isDatabaseAddonSetted) {
            setFormAddon(compareDatabaseAndFormValues(databaseAddon, formAddon));
            setIsFormAddonSetted(true);
        }


    }, [addonId, addonLoaded, countries, databaseAddon, elapsedSeconds, formAddon, isDatabaseAddonSetted, isFormAddonSetted, languages, timer]);

    useEffect(() => {
        // Starts the minutes counting interval when 60 seconds have passed
        if (elapsedSeconds >= 60) {
            setElapsedSeconds(0);
            setElapsedMinutes(1);

            clearInterval(timer);

            setTimer(setInterval( () => {
                setElapsedMinutes(setElapsedMinutes => setElapsedMinutes + 1);
            }, 60000));
        }

    }, [elapsedSeconds, timer])

    useEffect(() => {
        // Stops the time elapsed interval if there was an error on submitting the form
        if (hasAnError && !hasSucceeded) {
            setElapsedSeconds(0);
            setElapsedMinutes(0);

            clearInterval(timer);
        }
    }, [hasAnError, hasSucceeded, timer]);

    const submit = async (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        NProgress.start();

        setElapsedSeconds(0);
        setElapsedMinutes(0);

        clearInterval(timer);
        setTimer(setInterval( () => {
            setElapsedSeconds(elapsedMinutes => elapsedMinutes + 1);
        }, 1000));

        const collectedResponses = await submitForm(addonId, formAddon, databaseAddon, languages);

        setAddonName(formAddon.generalInformation.addonName);
        // Prevents multiple sending of the same values before the databaseAddon has been reloaded somehow
        setAddonLoaded(false);

        proofForErrorMessages(collectedResponses);

        NProgress.done();
    };

    function proofForErrorMessages(errorMessages: []): void {
        setErrorMessages(errorMessages);
        setHasAnError(false);
        setHasSucceeded(true);

        let messages = [];

        errorMessages.map((inputfield: {"errorMessage": string}) => (
            messages.push(inputfield.errorMessage)
        ));

        for (let i = 0; i < messages.length; i++) {
            if (messages[i] !== '') {
                setHasAnError(true);
                setHasSucceeded(false);
            }
        }
    }

    const createAccessDataType = async (
        index: number,
        value: string,
    ): Promise<ChangeAddonValue> => {
        const createAccessDataTypeResponse = await createAccessDataTypeApi(value);

        const accessDataResponse = await getAccessDataTypeIdByName(value);

        if (createAccessDataTypeResponse.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        const accessData = [...formAddon.accessDatas];
        accessData[index].accessDataType = value;
        accessData[index].accessDataTypeId = accessDataResponse.body.accessDataTypeId;

        setFormAddon({
            ...formAddon,
            accessDatas: accessData
        });

        return {
            'fieldName': 'accessDataType',
            'accessDataTypeId': accessDataResponse.accessDataTypeId,
            'errorMessage': createAccessDataTypeResponse.errorMessage
        }
    };

    function backToAddonList(): void {
        setBackToList(true);
    }

    function RedirectToAddonList(): React$Element<typeof Redirect> {
        if (redirectInformation !== null && backToList) {
            const templateId = redirectInformation.templateId === null || redirectInformation.templateId === '' ? '' : `template=${redirectInformation.templateId}&`;
            const orderBy    = `${redirectInformation.orderBy.order}=${redirectInformation.orderBy.key}`;
            const selectedTemplateFieldsWithValues = redirectInformation.selectedTemplateFieldsWithValues;

            let queryString = Object.keys(selectedTemplateFieldsWithValues).map(function (key) {
                return key + '=' + selectedTemplateFieldsWithValues[key]
            }).join('&');

            if (templateId !== '' || Object.keys(selectedTemplateFieldsWithValues).length !== 0) {
                return <Redirect to={`/addon/list#${templateId === 'template=&' ? '' : templateId}${orderBy}&${queryString}`}/>
            }
        }

        if (backToList) {
            return <Redirect to={`/addon/list`}/>
        }
    }

    function handleChange(formPart: string, event: SyntheticInputEvent<HTMLInputElement>): void {
        if (event.target.value.length > 255) {
            return;
        }

        if (event.target.name === 'mobilePhone') {
            setFormAddon({
                ...formAddon,
                [formPart]: {
                    ...formAddon[formPart],
                    [event.target.name]: {
                        ...formAddon[formPart][event.target.name],
                        number: event.target.value,
                    },
                }
            });
            return;
        }

        setFormAddon({
            ...formAddon,
            [formPart]: {
                ...formAddon[formPart],
                [event.target.name]: event.target.value,
            },
        });

        if (event.target.value === dropdownDefaultValue) {
            setFormAddon({
                ...formAddon,
                [formPart]: {
                    ...formAddon[formPart],
                    [event.target.name]: '',
                }
            });
        }

        if (event.target.name === 'ownerEmail') {
            formAddon.accessDatas[0].accessDataEmail = event.target.value;
        }

        if (event.target.name === 'ownerEmailPassword') {
            formAddon.accessDatas[0].accessDataEmailPassword = event.target.value;
        }
    }

    function trimValue(formPart: string, event: SyntheticInputEvent<HTMLInputElement>): void {
        if (event.target.value.length > 255) {
            return;
        }

        setFormAddon({
            ...formAddon,
            [formPart]: {
                ...formAddon[formPart],
                [event.target.name]: event.target.value.trim(),
            },
        });

        if (event.target.value === dropdownDefaultValue) {
            setFormAddon({
                ...formAddon,
                [formPart]: {
                    ...formAddon[formPart],
                    [event.target.name]: '',
                }
            });
        }

        if (event.target.name === 'ownerEmail') {
            formAddon.accessDatas[0].accessDataEmail = event.target.value.trim();
        }

        if (event.target.name === 'ownerEmailPassword') {
            formAddon.accessDatas[0].accessDataEmailPassword = event.target.value.trim();
        }
    }

    function handleDataRecordValueChange(
        formPart: string,
        key: number,
        event: SyntheticInputEvent<HTMLInputElement>
    ): void {
        let length = 255;

        if (event.target.maxLength !== -1) {
            length = event.target.maxLength;
        }

        if (event.target.value.length > length) {
            return;
        }

        const dataRecordValues = [...formAddon[formPart]];
        dataRecordValues[key][event.target.name] = event.target.value;

        setFormAddon({
            ...formAddon,
            [formPart]: dataRecordValues
        });
    }

    function trimDataRecordValue(formPart: string, key: number, event: SyntheticInputEvent<HTMLInputElement>): void {
        let length = 255;

        if (event.target.maxLength !== -1) {
            length = event.target.maxLength;
        }

        if (event.target.value.length > length) {
            return;
        }

        const dataRecordValues = [...formAddon[formPart]];
        dataRecordValues[key][event.target.name] = event.target.value.trim();

        setFormAddon({
            ...formAddon,
            [formPart]: dataRecordValues
        });
    }

    function handleAccessDataSelectChange(
        fieldName: string,
        key: number,
        option: [{
            label: string,
            value: string,
        },]
    ): void {
        if (option[0].label === option[0].value) {
            return;
        }

        const accessDataValues = [...formAddon.accessDatas];
        accessDataValues[key][fieldName] = option[0].label;

        if (fieldName === 'accessDataType') {
            accessDataValues[key]['accessDataTypeId'] = option[0].value;
        }

        setFormAddon({
            ...formAddon,
            accessDatas: accessDataValues
        });
    }

    function handleTextChange(formPart: string, fieldName: string, event: SyntheticInputEvent<HTMLInputElement>): void {
        if ((fieldName === 'ownerAnnotation' || fieldName === 'description') && event.target.value.length > 5000) {
            return;
        }

        if ((fieldName === 'privacyPolicy' || fieldName === 'storeDescription') && event.target.value.length > 65000) {
            setInputValueReachedMaxLength({
                ...inputValueReachedMaxLength,
                [fieldName]: true,
            });

            return;
        }

        setInputValueReachedMaxLength({
            ...inputValueReachedMaxLength,
            [fieldName]: false,
        });

        setFormAddon({
            ...formAddon,
            [formPart]: {
                ...formAddon[formPart],
                [fieldName]: event.target.value,
            },
        });
    }

    function trimTextValues(formPart: string, fieldName: string, event: SyntheticInputEvent<HTMLInputElement>): void {
        if ((fieldName === 'ownerAnnotation' || fieldName === 'description') && event.target.value.length > 5000) {
            return;
        }

        if ((fieldName === 'privacyPolicy' || fieldName === 'storeDescription') && event.target.value.length > 65000) {
            setInputValueReachedMaxLength({
                ...inputValueReachedMaxLength,
                [fieldName]: true,
            });

            return;
        }

        setInputValueReachedMaxLength({
            ...inputValueReachedMaxLength,
            [fieldName]: false,
        });

        setFormAddon({
            ...formAddon,
            [formPart]: {
                ...formAddon[formPart],
                [fieldName]: event.target.value.trim(),
            },
        });
    }

    function handleDateValueChange(formPart:string, fieldName: string, event: SyntheticInputEvent<HTMLInputElement>): void {
        let date = null;

        if (event !== null) {
            date = new Date(event);
        }

        setFormAddon({
            ...formAddon,
            [formPart]: {
                ...formAddon[formPart],
                [fieldName]: date,
            }
        });

        setAddonLoaded(false);
    }

    function handlePlannedCountriesChange(event: ?[]): void {
        let countries = [];

        if (event !== null) {
            event.map((country: {value: string, label: string, usages: string}) => (
                countries.push(country.value)
            ));
        }

        setFormAddon({
            ...formAddon,
            generalInformation: {
                ...formAddon.generalInformation,
                plannedCountries: [...countries],
            },
        });
    }

    function handleMobilePhoneChange(event: ?{}): void {
        setFormAddon({
            ...formAddon,
            generalInformation: {
                ...formAddon.generalInformation,
                mobilePhone: {
                    ...formAddon.generalInformation.mobilePhone,
                    number: event.value,
                },
            },
        });
    }

    async function uploadAttachments(fieldName: string, event: SyntheticInputEvent<HTMLInputElement>): void {
        setInvalidFiles([]);
        setInputValueReachedMaxLength({
            ...inputValueReachedMaxLength,
            [fieldName]: false,
        });

        let anyFileSizeIsInvalid = false;
        let files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > 100000000 || files[i].size === 0) {
                setInvalidFiles(invalidFiles => [
                    ...invalidFiles,
                    files[i].name + ' (' + formatBitNumber(files[i].size) + ')',
                ]);

                anyFileSizeIsInvalid = true;

                continue;
            }

            setIsCurrentUploading(
                <Button variant="light" disabled>
                    <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="small">Uploading {files[i].name}</span>
                </Button>
            );

            const response = await uploadFile(files[i], addonId);

            if (response === null) {
                throw new NotAuthorizedError('Not authorized');
            }

            setFormAddon(formAddon => {
                const oldIndex = formAddon.attachments.findIndex(a => a.attachmentName === response.attachmentName);

                if (oldIndex === -1) {
                    return {
                        ...formAddon,
                        attachments: [
                            response,
                            ...formAddon.attachments,
                        ],
                    }
                }

                return {
                    ...formAddon,
                    attachments: [
                        response,
                        ...formAddon.attachments.slice(0, oldIndex),
                        ...formAddon.attachments.slice(oldIndex + 1),
                    ],
                };
            });
        }

        if (!anyFileSizeIsInvalid) {
            setIsCurrentUploading(<span />);
        }

        if (anyFileSizeIsInvalid) {
            setIsCurrentUploading(
                <span className="small">Nicht alle Dateien konnten hochgeladen werden</span>
            );
            setInputValueReachedMaxLength({
                ...inputValueReachedMaxLength,
                [fieldName]: true
            });
        }

        setAddonLoaded(false);
    }

    function closeUploadAlert(): void {
        setIsCurrentUploading(<span />);
        setInvalidFiles([]);
        setInputValueReachedMaxLength({
            ...inputValueReachedMaxLength,
            fileUpload: false
        });
    }

    async function downloadAttachment(attachmentName: string): void {
        const response = await downloadAttachmentApi(addonId, attachmentName)

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        if (response.status === 201) {
            let byteString = atob(response.attachment.content);
            let arrayBuffer = new ArrayBuffer(byteString.length);
            let byteArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                byteArray[i] = byteString.charCodeAt(i);
            }

            let file = new File([arrayBuffer], response.attachment.fileName, {type: response.attachment.type});

            saveAs(file);
        }
    }

    async function deleteAttachment(attachmentId: string, attachmentName: string, index: number): void {
        const response = await deleteAttachmentApi(addonId, attachmentId, attachmentName)

        if (response.status === 201) {
            setFormAddon({
                ...formAddon,
                attachments: [
                    ...formAddon.attachments.slice(0, index),
                    ...formAddon.attachments.slice(index + 1),
                ]
            })
        }

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        setAddonLoaded(false);
    }

    async function createDomainPathElement(): void {
        let result = await changeAddonValue(addonId, 'manually_create_addon_domain_path_record', '', '');

        setFormAddon({
            ...formAddon,
            domainPathes: [
                ...formAddon.domainPathes,
                {
                    domainPathId: result.body.domainPathId,
                    domainPath: null,
                    domainPathDescription: null,
                },
            ],
        });

        setAddonLoaded(false);
    }

    async function deleteDomainPathElement(index: number): void {
        const domainPathId = formAddon.domainPathes[index].domainPathId;

        setFormAddon({
            ...formAddon,
            domainPathes: [
                ...formAddon.domainPathes.slice(0, index),
                ...formAddon.domainPathes.slice(index + 1),
            ],
        });

        await changeAddonValue(addonId, 'delete_addon_domain_path_record', 'domainPathId', domainPathId);

        setAddonLoaded(false);
    }

    async function createAccessDataElement(): void {
        let result = await changeAddonValue(addonId, 'manually_create_addon_access_data_record', '', '');

        setFormAddon({
            ...formAddon,
            accessDatas: [
                ...formAddon.accessDatas,
                {
                    accessDataId: result.body.accessDataId,
                    addonId: result.body.addonId,
                    consecutiveAddonId: databaseAddon.consecutiveAddonId,
                    addonName: null,
                    accessDataTypeId: null,
                    accessDataType: null,
                    accessDataName: null,
                    accessDataEmail: null,
                    accessDataEmailPassword: null,
                    accessDataAnnotation: null,
                },
            ],
        });

        setAddonLoaded(false);
    }

    async function deleteAccessDataElement(index: number): void {
        const accessDataId = formAddon.accessDatas[index].accessDataId;

        setFormAddon({
            ...formAddon,
            accessDatas: [
                ...formAddon.accessDatas.slice(0, index),
                ...formAddon.accessDatas.slice(index + 1),
            ],
        });

        await changeAddonValue(addonId, 'delete_addon_access_data_record', 'accessDataId', accessDataId);

        setAddonLoaded(false);
    }

    return (
        <>
            {RedirectToAddonList()}
            <Card className="sticky-top z-index bg-blue">
                <div className="flex justify-content-between height-60">
                    <BackButton backTo={backToAddonList} />
                    <div className="flex justify-content-between width">
                        <AddonFormResultMessage
                            hasSucceeded={hasSucceeded}
                            hasAnError={hasAnError}
                            addonName={addonName}
                            elapsedSeconds={elapsedSeconds}
                            elapsedMinutes={elapsedMinutes}
                        />
                        <SubmitButton submit={submit} addonLoaded={addonLoaded}/>
                    </div>
                </div>
            </Card>
            <Card>
                <Card.Body>
                    <Form onSubmit={submit}>
                        <div className="flex-flow-wrap">
                            <GeneralInformation
                                generalInformation={formAddon.generalInformation}
                                languages={languages}
                                countries={countries}
                                mobilePhones={mobilePhones}
                                handleChange={handleChange}
                                handleTextChange={handleTextChange}
                                handleDateValueChange={handleDateValueChange}
                                handlePlannedCountriesChange={handlePlannedCountriesChange}
                                handleMobilePhoneChange={handleMobilePhoneChange}
                                trimValue={trimValue}
                                trimTextValues={trimTextValues}
                                errorMessages={errorMessages}
                            />
                            <OwnerInformation
                                ownerInformation={formAddon.ownerInformation}
                                countries={countries}
                                handleChange={handleChange}
                                handleTextChange={handleTextChange}
                                handleDateValueChange={handleDateValueChange}
                                trimValue={trimValue}
                                trimTextValues={trimTextValues}
                                errorMessages={errorMessages}
                            />
                            <DomainPathes
                                formAddon={formAddon}
                                createDomainPathElement={createDomainPathElement}
                                deleteDomainPathElement={deleteDomainPathElement}
                                handleDataRecordValueChange={handleDataRecordValueChange}
                                trimDataRecordValue={trimDataRecordValue}
                                errorMessages={errorMessages}
                            />
                            <AccessDatas
                                formAddon={formAddon}
                                createAccessDataElement={createAccessDataElement}
                                deleteAccessDataElement={deleteAccessDataElement}
                                handleDataRecordValueChange={handleDataRecordValueChange}
                                handleAccessDataSelectChange={handleAccessDataSelectChange}
                                createAccessDataType={createAccessDataType}
                                trimDataRecordValue={trimDataRecordValue}
                                errorMessages={errorMessages}
                            />
                            <Attachments
                                formAddon={formAddon}
                                downloadAttachment={downloadAttachment}
                                deleteAttachment={deleteAttachment}
                                uploadAttachments={uploadAttachments}
                                inputValueReachedMaxLength={inputValueReachedMaxLength}
                                isCurrentUploading={isCurrentUploading}
                                invalidFiles={invalidFiles}
                                closeUploadAlert={closeUploadAlert}
                                handleDataRecordValueChange={handleDataRecordValueChange}
                                trimDataRecordValue={trimDataRecordValue}
                            />
                            <div className="item-100">
                                <Form.Row>
                                    <div className="flex justify-content-between further-information-form">
                                        <PrivacyPolicy
                                            privacyPolicy={formAddon.privacyPolicy}
                                            handleTextChange={handleTextChange}
                                            trimTextValues={trimTextValues}
                                            errorMessages={errorMessages}
                                            inputValueReachedMaxLength={inputValueReachedMaxLength}
                                        />
                                        <StoreDescription
                                            storeDescription={formAddon.storeDescription}
                                            handleTextChange={handleTextChange}
                                            trimTextValues={trimTextValues}
                                            errorMessages={errorMessages}
                                            inputValueReachedMaxLength={inputValueReachedMaxLength}
                                        />
                                    </div>
                                </Form.Row>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
}
