// @flow

import React from 'react';
import Button from "react-bootstrap/Button";

const NotFound = () => (
    <div className="not-found">
        <h2>404 Not Found</h2>
        <hr/>
        <div className="not-found__footer">Die angeforderte Ressource konnte nicht gefunden werden</div>
        <Button size="sm" variant="outline-dark" href="/">Zurück</Button>
    </div>
);

export default NotFound;
