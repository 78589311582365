// @flow

export type TemplateListResponse = {
    templates: [
        {
            templateId: string,
            templateName: string,
        },
    ],
    error: string,
    errorMessage: string,
    status: ?number,
}

export async function loadTemplatesApi(): Promise<TemplateListResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/template/list', {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            templates: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            templates: [],
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export type TemplateValuesResponse = {
    template: {
        templateId: string,
        templateName: string,
        templateKeys: [],
    },
    error: string,
    errorMessage: string,
    status: ?number,
}

export async function loadTemplateValuesApi(templateId: string): Promise<TemplateValuesResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/template/keys/' + templateId, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            template: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            template: [],
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export type TemplateResponse = {
    body: {
        templateId: string,
        templateName: string,
        templateKeys: [],
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export async function createTemplateApi(templateName: string, backendKeyFilters: []): Promise<TemplateResponse> {
    if (backendKeyFilters[0] === 'template') {
        backendKeyFilters = backendKeyFilters.slice(1);
    }

    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'create_addon_list_template',
                payload: {
                    templateName: templateName,
                    templateKeys: backendKeyFilters,
                }
            }),
        });

        const data = await response.json();

        return {
            body: data.body,
            success: true,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
        return {
            body: {},
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function changeTemplateApi(templateId: string, templateName: string, backendKeyFilters: []): Promise<TemplateResponse> {
    if (backendKeyFilters[0] === 'template') {
        backendKeyFilters = backendKeyFilters.slice(1);
    }

    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'change_addon_list_template',
                payload: {
                    templateName: templateName,
                    templateId: templateId,
                    templateKeys: backendKeyFilters,
                }
            }),
        });

        const data = await response.json();

        return {
            body: data.body,
            success: true,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
        return {
            body: {},
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
