// @flow

import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import Select from 'react-dropdown-select';
import {loadDropdownValuesFromApi} from "../../../../Api/DropdownApi";
import NotAuthorizedError from "../../../../Authorization/NotAuthorizedError";
import Feedback from "../../Feedback";
import {DeleteRecordButton} from "../../../Helper/Buttons";

type AccessDataElementProps = {
    // Properties to fill inputfields with initial values
    index: number,
    accessDataType: null|string,
    accessDataTypeId: null|string,
    accessDataName: null|string,
    accessDataEmail: null|string,
    accessDataEmailPassword: null|string,
    accessDataAnnotation: null|string,
    // Properties to handle changing values of the form
    handleDataRecordValueChange: Function,
    handleAccessDataSelectChange: Function,
    createAccessDataType: Function,
    deleteAccessDataElement: Function,
    trimDataRecordValue: Function,
    // Properties to render messages within the form
    errorMessages: [],
}

export default function AccessDataElement(
    {
        index,
        accessDataType,
        accessDataTypeId,
        accessDataName,
        accessDataEmail,
        accessDataEmailPassword,
        accessDataAnnotation,
        handleDataRecordValueChange,
        handleAccessDataSelectChange,
        createAccessDataType,
        deleteAccessDataElement,
        trimDataRecordValue,
        errorMessages
    }: AccessDataElementProps
) {
    const [accessDataTypes, setAccessDataTypes] = useState<[]>([]);
    const [areAccessDataTypesActual, setAreAccessDataTypesActual] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

    useEffect(() => {
        if (!areAccessDataTypesActual) {
            loadAccessDataTypes();
        }

        async function loadAccessDataTypes() {
            const response = await loadDropdownValuesFromApi('accessDataType');

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            } else {
                let accessDataArray = [];
                response.values.map((accessDatas: { accessDataTypeId: string, accessDataType: string }) => (
                    accessDataArray.push({value: accessDatas.accessDataTypeId, label: accessDatas.accessDataType})
                ));

                setAccessDataTypes(accessDataArray);
                setAreAccessDataTypesActual(true);
            }
        }
    }, [areAccessDataTypesActual]);

    return (
        <>
            <Form.Row>
                <div className="flex justify-content-between access-data-form">
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Select
                                create
                                name="accessDataType"
                                values={[{label: accessDataType || '', value: accessDataTypeId || ''}]}
                                onChange={handleAccessDataSelectChange.bind(this, 'accessDataType', index)}
                                onCreateNew={handleCreateAccessDataType.bind(this, index)}
                                options={accessDataTypes}
                            />
                            <Feedback errorMessages={errorMessages} fieldName={'accessDataType' + index}/>
                        </Form.Group>
                    </div>
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                name="accessDataName"
                                value={accessDataName || ''}
                                onChange={handleDataRecordValueChange.bind(this, 'accessDatas', index)}
                                onBlur={trimDataRecordValue.bind(this, 'accessDatas', index)}
                                placeholder="Name / Kurze Beschreibung"
                            />
                            <Feedback errorMessages={errorMessages} fieldName={'accessDataEmail' + index}/>
                        </Form.Group>
                    </div>
                    <ReadOnlyEmail
                        accessDataEmail={accessDataEmail}
                        handleDataRecordValueChange={handleDataRecordValueChange}
                        trimDataRecordValue={trimDataRecordValue}
                        index={index}
                        errorMessages={errorMessages}
                    />
                    <ReadOnlyEmailPassword
                        accessDataEmailPassword={accessDataEmailPassword}
                        handleDataRecordValueChange={handleDataRecordValueChange}
                        trimDataRecordValue={trimDataRecordValue}
                        index={index}
                        errorMessages={errorMessages}
                    />
                    <div className="access-data-form-element">
                        <Form.Group>
                            <Form.Control
                                className="access-data-textarea-height"
                                size="sm"
                                as="textarea"
                                rows={3}
                                maxLength={5000}
                                name="accessDataAnnotation"
                                value={accessDataAnnotation || ''}
                                onChange={handleDataRecordValueChange.bind(this, 'accessDatas', index)}
                                onBlur={trimDataRecordValue.bind(this, 'accessDatas', index)}
                                placeholder="Anmerkungen"
                            />
                            <Feedback errorMessages={errorMessages} fieldName={'accessDataAnnotation' + index}/>
                        </Form.Group>
                    </div>
                    <DeleteRecordButton
                        name="deleteAccessDataElement"
                        className="access-data-form-button"
                        setShowConfirmDialog={setShowConfirmDialog}
                        index={index}
                    />
                </div>
                <Modal show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Möchten Sie diese Zugangsdaten wirklich löschen?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowConfirmDialog(false)}
                        >Abbrechen</Button>
                        <Button
                            variant="danger"
                            onClick={() => deleteAccessDataElementAndHideDialog()}
                        >Löschen</Button>
                    </Modal.Footer>
                </Modal>
            </Form.Row>
        </>
    );

    function deleteAccessDataElementAndHideDialog() {
        deleteAccessDataElement(index);
        setShowConfirmDialog(false);
    }

    async function handleCreateAccessDataType(index: number, newOption: {label: string, value: string}) {
        if (newOption.label.length > 255) {
            return;
        }

        const response = await createAccessDataType(index, newOption.label);

        setAccessDataTypes([
            ...accessDataTypes,
            {
                value: response.accessDataTypeId,
                label: newOption.label,
            }
        ]);

        setAreAccessDataTypesActual(false);
    }
}

type ReadOnlyEmailProps = {
    accessDataEmail: null|string,
    handleDataRecordValueChange: Function,
    index: number,
    trimDataRecordValue: Function,
    errorMessages: [],
}

function ReadOnlyEmail(
    {
        accessDataEmail,
        handleDataRecordValueChange,
        index,
        trimDataRecordValue,
        errorMessages
    }: ReadOnlyEmailProps
) {
    return (
        <div className="access-data-form-element">
            <Form.Group>
                <Form.Control
                    readOnly={readOnly(index)}
                    size="sm"
                    type="text"
                    name="accessDataEmail"
                    value={accessDataEmail || ''}
                    onChange={handleDataRecordValueChange.bind(this, 'accessDatas', index)}
                    onBlur={trimDataRecordValue.bind(this, 'accessDatas', index)}
                    placeholder="Username / E-Mail"
                />
                <Feedback errorMessages={errorMessages} fieldName={'accessDataEmail' + index}/>
            </Form.Group>
        </div>
    );
}

type ReadOnlyEmailPasswordProps = {
    accessDataEmailPassword: null|string,
    handleDataRecordValueChange: Function,
    index: number,
    trimDataRecordValue: Function,
    errorMessages: [],
}

function ReadOnlyEmailPassword(
    {
        accessDataEmailPassword,
        handleDataRecordValueChange,
        index,
        trimDataRecordValue,
        errorMessages
    }: ReadOnlyEmailPasswordProps
) {
    return (
        <div className="access-data-form-element">
            <Form.Group>
                <Form.Control
                    readOnly={readOnly(index)}
                    size="sm"
                    type="text"
                    name="accessDataEmailPassword"
                    value={accessDataEmailPassword || ''}
                    onChange={handleDataRecordValueChange.bind(this, 'accessDatas', index)}
                    onBlur={trimDataRecordValue.bind(this, 'accessDatas', index)}
                    placeholder="Passwort"
                />
                <Feedback
                    errorMessages={errorMessages}
                    fieldName={'accessDataEmailPassword' + index}
                />
            </Form.Group>
        </div>
    );
}

function readOnly(index: number): boolean {
    return index === 0;
}
