// @flow

import React from "react";
import {Alert, Button, Card, Form} from "react-bootstrap";
import {getColumnName} from "./AddonListHelper";

type TemplateContainerProps = {
    templates: null|[],
    templateId: string,
    templateName: null|string,
    hasCurrentTemplateChanged: boolean,
    hasAnError: null|boolean,
    defaultTemplateId: null|string,
    handleTemplateNameChange: Function,
    createTemplate: Function,
    useTemplate: Function,
    changeTemplate: Function,
    templateFields: null|{},
    selectedTemplateFieldsWithValues: null|{},
    selectTemplateField: Function,
    setDefaultTemplate: Function,
}

export default function TemplateContainer(
    {
        templates,
        templateId,
        templateName,
        hasCurrentTemplateChanged,
        hasAnError,
        defaultTemplateId,
        handleTemplateNameChange,
        createTemplate,
        useTemplate,
        changeTemplate,
        templateFields,
        selectedTemplateFieldsWithValues,
        selectTemplateField,
        setDefaultTemplate
    }: TemplateContainerProps
) {
    if (!templates || !templateFields || !selectedTemplateFieldsWithValues) {
        return null;
    }

    return (
        <>
            <div className="flex">
                <Card body="true" className="flex addon-list-template-container">
                    <div className="flex">
                        <TemplateForm
                            templateId={templateId}
                            defaultTemplateId={defaultTemplateId}
                            templateName={templateName}
                            hasCurrentTemplateChanged={hasCurrentTemplateChanged}
                            hasAnError={hasAnError}
                            createTemplate={createTemplate}
                            changeTemplate={changeTemplate}
                            handleTemplateNameChange={handleTemplateNameChange}
                            setDefaultTemplate={setDefaultTemplate}
                        />
                        <TemplateList
                            templateId={templateId}
                            templates={templates}
                            defaultTemplateId={defaultTemplateId}
                            useTemplate={useTemplate}
                        />
                    </div>
                </Card>
                <TemplateFieldCard
                    selectedTemplateFieldsWithValues={selectedTemplateFieldsWithValues}
                    templateFields={templateFields}
                    selectTemplateField={selectTemplateField}
                />
            </div>
        </>
    );
}

type TemplateFormProps = {
    templateId: string,
    defaultTemplateId: string,
    templateName: null|string,
    hasAnError: null|boolean,
    createTemplate: Function,
    changeTemplate: Function,
    handleTemplateNameChange: Function,
    setDefaultTemplate: Function,
}

function TemplateForm(
    {
        templateId,
        defaultTemplateId,
        templateName,
        hasCurrentTemplateChanged,
        hasAnError,
        createTemplate,
        changeTemplate,
        handleTemplateNameChange,
        setDefaultTemplate
    }: TemplateFormProps
) {
    return (
        <>
            <div className="addon-list-template-form">
                <Card.Title>Templates</Card.Title>
                <Form.Control
                    size="sm"
                    type="text"
                    value={templateName || ''}
                    onChange={handleTemplateNameChange}
                    placeholder="Name des Templates"
                />
                <div className="flex-column mt-3">
                    <div className="mb-2">
                        <Button
                            disabled={isCreatingDisabled()}
                            size="sm"
                            type="button"
                            variant="outline-dark"
                            onClick={createTemplate.bind(this, templateName)}
                        >Neues Template speichern</Button>
                    </div>
                    <div className="mb-2">
                        <Button
                            disabled={!hasCurrentTemplateChanged}
                            size="sm"
                            type="button"
                            variant="outline-dark"
                            value={templateId || ''}
                            onClick={changeTemplate.bind(this, templateName)}
                        >Ausgewähltes Template aktualisieren</Button>
                    </div>
                    <Form.Check
                        className="addon-list-default-template"
                        id="addonListDefaultTemplate"
                        disabled={isSelectDefaultDisabled()}
                        name="addonListDefault"
                        label="Als Default markieren"
                        onChange={setDefaultTemplate}
                        checked={templateId === defaultTemplateId}
                    />
                </div>
                <TemplateFormResultMessage hasAnError={hasAnError} />
            </div>
        </>
    );

    function isCreatingDisabled(): boolean {
        return !templateName;
    }

    function isSelectDefaultDisabled(): boolean {
        return templateId.length !== 36;
    }
}

type TemplateFormResultMessageProps = {
    hasAnError: null|boolean,
}

function TemplateFormResultMessage({hasAnError}: TemplateFormResultMessageProps) {
    if (hasAnError) {
        return (
            <Alert className="addon-list-template-message" variant="danger">Speichern fehlgeschlagen</Alert>
        );
    }

    if (hasAnError === false) {
        return (
            <Alert className="addon-list-template-message" variant="success">Template gespeichert</Alert>
        );
    }

    return null;
}

type TemplateListProps = {
    templateId: string,
    defaultTemplateId: string,
    templates: [],
    useTemplate: Function,
}

function TemplateList({templateId, defaultTemplateId, templates, useTemplate}: TemplateListProps) {
    if (templateId === '') {
        templateId = defaultTemplateId !== null ? defaultTemplateId : '';
    }

    return (
        <>
            <Card body="true" className="addon-list-template-list">
                <Form>
                    {templates.map((template: { templateId: string, templateName: string }, key) => (
                        <Form.Check
                            key={key}
                            id={template.templateId}
                            type="radio"
                            name="addonListTemplate"
                            label={template.templateName}
                            value={template.templateId}
                            onChange={useTemplate}
                            checked={templateId === template.templateId}
                        />
                    ))}
                </Form>
            </Card>
        </>
    );
}

type TemplateFieldCardProps = {
    selectedTemplateFieldsWithValues: {},
    templateFields: {},
    selectTemplateField: Function,
}

function TemplateFieldCard({selectedTemplateFieldsWithValues, templateFields, selectTemplateField}: TemplateFieldCardProps) {
    // Sets the initially selected template field values to true, to check the checkboxes.
    Object.keys(templateFields).map((templateFieldKey) => (templateFields[templateFieldKey] = false));
    Object.keys(selectedTemplateFieldsWithValues).map((filter) => (templateFields[filter] = true));
    return (
        <Card body="true" className="addon-list-template-fields">
            <Card.Title>Template Fields</Card.Title>
            <Card body="true" className="addon-list-template-checkboxes">
                {Object.keys(templateFields).map((fieldName, key) => (
                    <Form.Check
                        key={key}
                        id={fieldName}
                        name={fieldName}
                        label={getColumnName(fieldName)}
                        checked={templateFields[fieldName]}
                        onChange={selectTemplateField}
                    />
                ))}
            </Card>
        </Card>
    );
}
