// @flow

export type UploadAttachmentResponse = {
    body: {
        addonId: string,
        attachmentId: string,
        attachmentUploadDate: string,
        userIdentifier: string,
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export type DownloadAttachmentResponse = {
    attachment: {
        content: string,
        fileName: string,
        type: string,
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type DeleteAttachmentResponse = {
    body: {
        addonId: string,
        attachmentId: string,
        attachmentName: string,
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export async function uploadAttachment(addonId: string, formData: {}): Promise<UploadAttachmentResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
            body: formData
        });

        const data = await response.json();

        return {
            body: data.body,
            success: true,
            error: false,
            errorMessage: data.errorMessage,
            status: response.status,
        }
    } catch (e) {
        return {
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function downloadAttachmentApi(addonId: string, attachmentName: string): Promise<DownloadAttachmentResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/addon/download/' + addonId + '/' + attachmentName, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            attachment: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            attachment: {},
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function deleteAttachmentApi(addonId: string, attachmentId: string, attachmentName: string): Promise<DeleteAttachmentResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'delete_addon_attachment',
                payload: {
                    addonId: addonId,
                    attachmentId: attachmentId,
                    attachmentName: attachmentName
                }
            }),
        });

        const data = await response.json();

        return {
            body: data.body,
            success: true,
            error: false,
            errorMessage: data.errorMessage,
            status: response.status,
        }
    } catch (e) {
        return {
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
