// @flow

import React, {useEffect, useState} from "react";
import {loadAccessDatasFromApi} from "../Api/AccessDataApi";
import NotAuthorizedError from "../Authorization/NotAuthorizedError";
import {Card, Form, Table} from "react-bootstrap";
import {loadDropdownValuesFromApi} from "../Api/DropdownApi";

export type AccessData = {
    accessDataId: string,
    addonId: string,
    consecutiveAddonId: string,
    addonName: ?string,
    accessDataTypeId: ?string,
    accessDataType: ?string,
    accessDataEmail: ?string,
    accessDataEmailPassword: ?string,
    accessDataAnnotation: ?string,
}

export default function AccessDataList() {
    const [accessDatas, setAccessDatas] = useState<null|AccessData[]>(null);
    const [filters, setFilters] = useState<null|[]>(null);
    const [selectedFilter, setSelectedFilter] = useState<string>('Typ');

    useEffect(() => {
        if (!accessDatas) {
            loadAccessDatas();
        }

        async function loadAccessDatas() {
            const response = await loadAccessDatasFromApi('all');

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setAccessDatas(response.accessDatas);
        }

    }, [accessDatas]);

    useEffect(() => {
        if (!filters) {
            setFilterOptions()
        }

        async function setFilterOptions() {
            const response = await loadDropdownValuesFromApi('accessDataType');

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            let filterValues = [];
            response.values.map((accessDatas: {accessDataTypeId: string, accessDataType: string}) => (
                filterValues.push({value: accessDatas.accessDataTypeId, label: accessDatas.accessDataType})
            ));

            setFilters(filterValues);
        }
    }, [filters]);

    return (
        <>
            <Card body="true">
                <div className="flex justify-content-between access-data-list">
                    <Card.Title>Zugangsdaten</Card.Title>
                    <AccessDataFilter />
                </div>
                <Card.Body>
                    <AccessDataTable />
                </Card.Body>
            </Card>
        </>
    );

    function AccessDataFilter() {
        if (!filters) {
            return null;
        }

        return (
            <>
                <div className="flex">
                    <div className="margin-filter">Typ:</div>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                as="select"
                                name="typeFilter"
                                value={selectedFilter}
                                onChange={doFilterType.bind(this)}
                            >
                                <option value={'all'}>Alle</option>
                                <option value={'null'}>Keine Angabe</option>
                                {filters.map((filter: {value: string, label: string}) =>
                                    <option key={filter.value} value={filter.value}>{filter.label}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </div>
            </>
        );
    }

    async function doFilterType(event: SyntheticInputEvent<HTMLInputElement>): void {
        setSelectedFilter(event.target.value);

        const response = await loadAccessDatasFromApi(event.target.value);

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        setAccessDatas(response.accessDatas);
    }

    function AccessDataTable() {
        return (
            <>
                <Table className="access-data-list__table">
                    <thead>
                        <tr>
                            <th className="table__addon-id">Addon ID</th>
                            <th className="table__addon-name">Addon</th>
                            <th className="table__type">Typ</th>
                            <th className="table__username">Email / Key</th>
                            <th className="table__password">Passwort</th>
                            <th className="table__annotation">Anmerkungen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody />
                    </tbody>
                </Table>
                <div className="text-muted small">* Kein Name gespeichert</div>
            </>
        );
    }

    function TableBody() {
        if (!accessDatas) {
            return null;
        }

        return accessDatas.map((accessData: AccessData) => (
            <AccessDataLineElement
                key={accessData.accessDataId}
                addonId={accessData.addonId}
                consecutiveAddonId={accessData.consecutiveAddonId}
                addonName={accessData.addonName}
                type={accessData.accessDataType}
                email={accessData.accessDataEmail}
                emailPassword={accessData.accessDataEmailPassword}
                annotation={accessData.accessDataAnnotation}
            />
        ));
    }
}

type AccessDataLineElementProps = {
    addonId: string,
    consecutiveAddonId: string,
    addonName: ?string,
    type: ?string,
    email: ?string,
    emailPassword: ?string,
    annotation: ?string,
}

function AccessDataLineElement(
    {
        addonId,
        consecutiveAddonId,
        addonName,
        type,
        email,
        emailPassword,
        annotation
    }: AccessDataLineElementProps
) {
    const defaultValue = <i className="text-muted">k.A.</i>;

    if (annotation) {
        annotation = <Form.Control
            readOnly
            plaintext
            as="textarea"
            rows={3}
            value={annotation || 'k.A'}
        />
    }

    return (
        <>
            <tr>
                <td className="table__addon-id">{consecutiveAddonId}</td>
                <td className="table__addon-name"><a href={"/addon/edit/" + addonId}>{addonName || addonId + ' *'}</a></td>
                <td className="table__type">{type || defaultValue}</td>
                <td className="table__username">{email || defaultValue}</td>
                <td className="table__password">{emailPassword || defaultValue}</td>
                <td className="table__annotation">{annotation || defaultValue}</td>
            </tr>
        </>
    );
}
