// @flow

export function formatDateForBackend(date: ?Date): string{
    if (date === null) {
        return '';
    }

    function pad(date: Date) {
        return date < 10 ? '0' + date : date
    }

    return date.getFullYear() + '-'
        + pad(date.getMonth() + 1) + '-'
        + pad(date.getDate()) + ' '
        + pad(date.getHours()) + ':'
        + pad(date.getMinutes()) + ':'
        + pad(date.getSeconds())
}

export function formatDateValuesForFrontend(addons: FilteredAddon[]): FilteredAddon[]{
    addons = addons.map((addon: FilteredAddon) => {
        if (addon.plannedLaunchDate) {
            addon.plannedLaunchDate = formatDateValue(addon.plannedLaunchDate);
        }

        if (addon.ownerBirthday) {
            addon.ownerBirthday = formatDateValue(addon.ownerBirthday);
        }

        return addon;
    });

    return addons;

    function formatDateValue(dateString: ?string): ?string {
        if (dateString === null) {
            return dateString;
        }

        const dateValues = dateString.split('-');

        return  dateValues[2] + '.' + dateValues[1] + '.' + dateValues[0];
    }
}

export function formatDateForDatePicker(dateString: string): Date {
    const dateValues = dateString.split('-');

    let year = dateValues[0],
        month = (parseInt(dateValues[1]) - 1),
        day = '' + dateValues[2];

    return new Date(parseInt(year), month, parseInt(day));
}

export function formatAttachmentDateValue(dateString: ?string): ?string {
    if (dateString === null) {
        return dateString;
    }

    const dateAndTime = dateString.split(' ');

    const dateValues = dateAndTime[0].split('-');

    return  dateValues[2] + '.' + dateValues[1] + '.' + dateValues[0] + ' ' + dateAndTime[1];
}
