// @flow

import {uploadAttachment} from "../../../Api/AttachmentApi";
import {formatDateForBackend} from "../../Helper/DateFormatter";

type Attachment = {
    attachmentId: string,
    attachmentName: string,
    attachmentUploadDate: string,
    attachmentLastModified: string,
    attachmentSize: string,
    attachmentType: string,
    attachmentFileData: string,
    attachmentUploader: string,
};

export default function uploadFile (file, addonId) :Promise<Attachment|null> {
    return new Promise<Attachment|null>((resolve, reject) => {
        let uploadDate = new Date();
        let reader = new FileReader();

        reader.onload = async function(event) {
            let attachment = {};
            attachment.attachmentName         = file.name;
            attachment.attachmentLastModified = file.lastModified;
            attachment.attachmentSize         = file.size;
            attachment.attachmentType         = file.type;
            attachment.attachmentFileData     = event.target.result;

            const uploadFile = new File([attachment.attachmentFileData], attachment.attachmentName)
            const formData = new FormData();
            formData.append('command', 'upload_addon_attachment');
            formData.append('files[]', uploadFile);
            formData.append('payload[addonId]', addonId);
            formData.append('payload[attachmentUploadDate]', formatDateForBackend(uploadDate));

            const response = await uploadAttachment(addonId, formData);

            if (response.status === 201) {
                attachment.attachmentId         = response.body.attachmentId;
                attachment.attachmentUploader   = response.body.userIdentifier;
                attachment.attachmentUploadDate = response.body.attachmentUploadDate;
                resolve(attachment);
            }

            if (response.status === 401) {
                reject(null);
            }
        }

        reader.readAsArrayBuffer(file);
    })
}
