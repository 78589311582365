// @flow

import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Form} from "react-bootstrap";
import {changeUserEmailApi, changeUserNameApi, changeUserRoleApi, loadUserFromApi} from "../Api/UserApi";
import FormFeedback from "reactstrap/es/FormFeedback";
import NotAuthorizedError from "../Authorization/NotAuthorizedError";
import type {User} from "./UserList";
import {loadDropdownValuesFromApi} from "../Api/DropdownApi";

type Props = {
    match: {
        params: {
            userId: string,
        },
    },
}

export default function (props: Props) {
    const [user, setUser] = useState<User>({});
    const [userName, setUserName] = useState('');
    const [userLoaded, setUserLoaded] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isNameInvalid, setIsNameInvalid] = useState(false);
    const [isRoleValid, setIsRoleValid] = useState(true);
    const [isRoleInvalid, setIsRoleInvalid] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [areUserRolesLoaded, setAreUserRolesLoaded] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [changeUserEmailResponse, setChangeUserEmailResponse] = useState({
        success: '',
        error: false,
        errorMessage: '',
    });
    const [changeUserNameResponse, setChangeUserNameResponse] = useState({
        success: '',
        error: false,
        errorMessage: '',
    });
    const [changeUserRoleResponse, setChangeUserRoleResponse] = useState({
        success: '',
        error: false,
        errorMessage: '',
    });

    useEffect(() => {
        if (!userLoaded) {
            loadUser();
        }

        async function loadUser() {
            const response = await loadUserFromApi(props.match.params.userId);

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setUserLoaded(true);
            setUser(response.user);
            setUserName(response.user.name);
        }

        if (!areUserRolesLoaded) {
            loadUserRoles();
            setAreUserRolesLoaded(true);
        }
    }, [userLoaded, props.match.params.userId, user.name, areUserRolesLoaded]);

    async function loadUserRoles() {
        const response = await loadDropdownValuesFromApi('userRole');

        if (response.status === 401) {
            throw new NotAuthorizedError('Not authorized');
        }

        setUserRoles(response.values);
    }

    const submit = async (event: SyntheticEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (isEmailValid && isNameValid && isRoleValid) {
            setSuccess(true);
            setError(false);

            const EmailResponse = await changeUserEmailApi(user.userId, user.email);
            const NameResponse = await changeUserNameApi(user.userId, user.name);
            const RoleResponse = await changeUserRoleApi(user.userId, user.userRole);

            setChangeUserEmailResponse(EmailResponse);
            setChangeUserNameResponse(NameResponse);
            setChangeUserRoleResponse(RoleResponse);
        }
    };

    return (
        <>
            <Card body="true">
                <Card.Title>Daten von {userName} ändern</Card.Title>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>E-Mail</Form.Label>
                            <Form.Control
                                type="email"
                                value={user.email || ''}
                                name="newEmail"
                                autoComplete="off"
                                onChange={validateAndHandleChange.bind(this, 'email', setIsEmailValid, setIsEmailInvalid)}
                                isValid={isEmailValid}
                                isInvalid={isEmailInvalid}
                                required
                            />
                            <FormFeedback>Invalides Feld</FormFeedback>
                            {renderErrorMessage('email')}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                autoComplete="off"
                                value={user.name || ''}
                                name="newName"
                                onChange={validateAndHandleChange.bind(this, 'name', setIsNameValid, setIsNameInvalid)}
                                isValid={isNameValid}
                                isInvalid={isNameInvalid}
                                required
                            />
                            <FormFeedback>Invalides Feld</FormFeedback>
                            {renderErrorMessage('name')}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Rolle</Form.Label>
                            <Form.Control
                                as="select"
                                name="userRole"
                                value={user.userRole}
                                onChange={validateAndHandleChange.bind(
                                    this,
                                    'userRole',
                                    setIsRoleValid,
                                    setIsRoleInvalid,
                                )}
                                required
                            >
                                {userRoles.map((userRole: {"id": string, "userRole": string}, key) => (
                                    <option key={key} value={userRole.id}>{userRole.userRole}</option>
                                ))}
                            </Form.Control>
                            <FormFeedback>Invalides Feld</FormFeedback>
                            {renderErrorMessage('role')}
                        </Form.Group>
                        <Button
                            type="submit"
                            name="save"
                            onClick={submit}
                            title="Speichern"
                            variant="outline-success"
                        >
                            Speichern
                        </Button>
                    </Form>
                    {renderResultMessage()}
                </Card.Body>
            </Card>
        </>
    );

    function validateAndHandleChange(
        key: string,
        validSetter: function,
        invalidSetter: function,
        event: SyntheticInputEvent<HTMLInputElement>
    ): void {
        if (key === 'userRole') {
            validSetter(true);
            invalidSetter(false);
            setUser({
                ...user,
                [key]: event.target.value
            })

            return;
        }

        let isValid;

        setSuccess(false);
        setError(false);

        if (event.target.value.length >= 3 && event.target.value.length <= 255) {
            isValid = true;

            if (key === 'email') {
                isValid = event.target.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
            }
        } else {
            isValid = false;
        }

        if (isValid) {
            setUser({
                ...user,
                [key]: event.target.value
            });
        }

        validSetter(isValid);
        invalidSetter(!isValid);
    }

    function renderErrorMessage(type: string) {
        if (type === 'email' && changeUserEmailResponse.error) {
            return <Alert variant={"danger"}>{changeUserEmailResponse.errorMessage}</Alert>
        }

        if (type === 'name' && changeUserNameResponse.error) {
            return <Alert variant={"danger"}>{changeUserNameResponse.errorMessage}</Alert>
        }

        if (type === 'role' && changeUserRoleResponse.error) {
            return <Alert variant={"danger"}>{changeUserRoleResponse.errorMessage}</Alert>
        }
    }

    function renderResultMessage() {
        if (error && !success) {
            return <Alert variant={"danger"}>Nicht alle Eingaben sind korrekt</Alert>;
        }

        if (success && !error) {
            return <Alert variant={"success"}>Benutzer bearbeitet</Alert>;
        }
    }
}
