// @flow

export type AddonListResponse = {
    addons: FilteredAddon[],
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type AddonResponse = {
    addon: Addon,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type CreateAddonResponse = {
    addonId: {
        addonId: string
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export type ChangeValueResponse = {
    body: {
        addonId: string,
    },
    error: boolean,
    errorMessage: string,
    status: ?number,
    success: boolean,
}

export type ChangeAddonDataRecordValueResponse = {
    body: {
        addonId: string,
        accessDataId: string,
    },
    success: boolean,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export async function loadFilteredAddonsFromApi(filterString: string): Promise<AddonListResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/addon/list?' + filterString, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            addons: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            addons: [],
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function loadSingleAddonFromApi(addonId: string): Promise<AddonResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/addon/edit/' + addonId, {
            credentials: 'include',
            method: 'GET',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            addon: data,
            error: false,
            errorMessage: '',
            status: response.status,
        }
    } catch (e) {
        return {
            addon: {},
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function createAddonApi(): Promise<CreateAddonResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: 'create_addon',
                payload: {
                }
            }),
        });

        const data = await response.json();

        return {
            addonId: data.body,
            success: true,
            error: false,
            errorMessage: '',
            status: response.status,
        };
    } catch (e) {
        return {
            addonId: {},
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function changeAddonValueApi(
    command: string,
    key: string,
    addonId: string,
    value: string
): Promise<ChangeValueResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: command,
                payload: {
                    addonId: addonId,
                    [key]: value,
                }
            }),
        });

        const data = await response.json();

        return {
            body: data.body,
            success: true,
            error: false,
            errorMessage: data.errorMessage,
            status: response.status,
        }
    } catch (e) {
        return {
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function changeAddonDataRecordValueApi(
    command: string,
    addonId: string,
    dataRecordIdType: string,
    dataRecordId: string,
    key: string,
    value: string
): Promise<ChangeAddonDataRecordValueResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: command,
                payload: {
                    addonId: addonId,
                    [dataRecordIdType]: dataRecordId,
                    [key]: value,
                }
            }),
        });

        const data = await response.json();

        return {
            body: data.body,
            success: true,
            error: false,
            errorMessage: data.errorMessage,
            status: response.status,
        }
    } catch (e) {
        return {
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function changeAddonAttachmentDataRecordValueApi(
    command: string,
    addonId: string,
    oldAttachmentId: string,
    newAttachmentName: string,
    oldAttachmentName: string,
    attachmentUploadDate: string
): Promise<ChangeAddonDataRecordValueResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/submit', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                command: command,
                payload: {
                    addonId: addonId,
                    oldAttachmentId: oldAttachmentId,
                    newAttachmentName: newAttachmentName,
                    oldAttachmentName: oldAttachmentName,
                    attachmentUploadDate: attachmentUploadDate,
                }
            }),
        });

        const data = await response.json();

        return {
            body: data.body,
            success: true,
            error: false,
            errorMessage: data.errorMessage,
            status: response.status,
        }
    } catch (e) {
        return {
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
