// @flow

export type LoginResponse = {
    success: boolean,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export type LogoutResponse = {
    success: boolean,
    error: boolean,
    errorMessage: string,
    status: ?number,
}

export async function loginApi(email: string, password: string): Promise<LoginResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/login', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const data = await response.json();

        return {
            success: true,
            error: data.error,
            errorMessage: data.errorMessage,
            status: response.status,
        }
    } catch (e) {
        return {
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}

export async function logoutApi(): Promise<LogoutResponse> {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_API_URL + '/logout', {
            credentials: 'include',
            method: 'POST',
            mode: 'cors',
            headers: {
                Preflight: 'force',
                Accept: 'application/json',
            },
        });

        const data = await response.json();

        return {
            success: true,
            error: false,
            errorMessage: data.errorMessage,
            status: response ? response.status : null,
        }

    } catch (e) {
        return {
            success: false,
            error: true,
            errorMessage: e.message,
            status: response ? response.status : null,
        }
    }
}
