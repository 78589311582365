// @flow

import React, {useEffect, useState} from "react";
import {Card, Table} from "react-bootstrap";
import {loadUsersFromApi} from "../Api/UserApi";
import UserLineElement from "./UserLineElement";
import NotAuthorizedError from "../Authorization/NotAuthorizedError";

export type User = {
    userId: string,
    id: string,
    email: string,
    name: string,
    userRole: string,
}

export default function () {
    const [users, setUsers] = useState([]);
    const [usersLoaded, setUsersLoaded] = useState(false);

    useEffect(() => {
        if (!usersLoaded) {
            loadUser();
        }

        async function loadUser() {
            const response = await loadUsersFromApi();

            if (response.status === 401) {
                throw new NotAuthorizedError('Not authorized');
            }

            setUsers(response.users);
            setUsersLoaded(true);
        }
    }, [usersLoaded]);


    return(
        <>
            <Card body="true">
                <Card.Title>Benutzer Liste</Card.Title>
                <Card.Body>
                    <Table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Rolle</th>
                            <th>Aktion</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderTableBody()}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );

    function renderTableBody(): React$Element<typeof UserLineElement>[] {
        return users.map((user: User, key) => (
            <UserLineElement
                key={key}
                userId={user.userId}
                id={user.id}
                email={user.email}
                name={user.name}
                userRole={user.userRole}
            />
        ));
    }
}
