// @flow

export function formatCountryOptions(
    countries: [
        {
            ISO_3166_1: string,
            country: string,
            usages: string,
        },
    ]
): [{value: string, label: string, usages: string}] {
    return (countries.map((country: {ISO_3166_1: string, country: string, usages: string}) => (
        {value: country.ISO_3166_1, label: country.country, usages: country.usages}
    )));
}

export function formatSelectedCountries(
    countries: [],
    selectableCountries: [
        {
            ISO_3166_1: string,
            country: string,
            usages: string,
        },
    ]
): [{value: string, label: string, usages: string}] {
    let selectedCountries = [];

    for (let i = 0; i < selectableCountries.length; i++) {
        countries.map((country: string) => {
            if (country === selectableCountries[i].ISO_3166_1) {
                selectedCountries.push({
                    value: selectableCountries[i].ISO_3166_1,
                    label: selectableCountries[i].country,
                    usages: selectableCountries[i].usages
                });
            }

            return selectedCountries;
        });
    }

    return selectedCountries;
}

export function formatMobilePhoneOptions(
    mobilePhones: [
        {
            number: string,
            manufacturer: string,
            usages: string,
        },
    ]
): [{value: string, label: string, manufacturer: string, usages: string}] {
    let mobilePhoneOptions = [];

    mobilePhoneOptions.push({value: "", label: "Bitte auswählen", manufacturer: "", usages: ""})

    mobilePhones.map((mobilePhone: {number: string, manufacturer: string, usages: string}) => (
        mobilePhoneOptions.push({
            value: mobilePhone.number,
            label: mobilePhone.number,
            manufacturer: `${mobilePhone.manufacturer} -`,
            usages: mobilePhone.usages
        })
    ));

    return mobilePhoneOptions;
}

export function formatSelectedNumber(
    mobilePhoneNumber: string,
    selectableMobilePhones: [
        {
            number: string,
            manufacturer: string,
            usages: string,
        },
    ]
): {value: string, label: string, manufacturer: string, usages: string} {
    let selectedNumberObject;

    if (!mobilePhoneNumber) {
        return {value: "", label: "Bitte auswählen", manufacturer: "", usages: ""};
    }

    for (let i = 0; i < selectableMobilePhones.length; i++) {
        if (selectableMobilePhones[i].number === mobilePhoneNumber) {
            selectedNumberObject = {
                value: selectableMobilePhones[i].number,
                label: selectableMobilePhones[i].number,
                manufacturer: `${selectableMobilePhones[i].manufacturer} -`,
                usages: selectableMobilePhones[i].usages,
            };
        }
    }

    return selectedNumberObject;
}
