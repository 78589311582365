// @flow

export default function formatBitNumber(bit: number): string {
    if (bit === 0) {
        return bit + ' Byte';
    }

    if (bit < 1000000 ) {
        bit /= 1000;

        bit = bit.toLocaleString('de');

        return bit + ' kB';
    }

    if (bit > 1000000 && bit < 1000000000) {
        bit /= 1000000;

        bit = bit.toLocaleString('de');

        return bit + ' MB';
    }

    if (bit > 1000000000 && bit < 1000000000000) {
        bit /= 1000000000;

        bit = bit.toLocaleString('de');

        return bit + ' GB';
    }

    if (bit > 1000000000000 && bit < 1000000000000000) {
        bit /= 1000000000000;

        bit = bit.toLocaleString('de');

        return bit + ' TB';
    }

    if (bit > 1000000000000000) {
        bit /= 1000000000000000;

        bit = bit.toLocaleString('de');

        return bit + ' PB';
    }
}
