// @flow

import React from "react";
import {Button} from "react-bootstrap";

type CreateAddonButtonProps = {
    createAddon: Function,
}

export function CreateAddonButton({createAddon}: CreateAddonButtonProps) {
    return(
        <>
            <div>
                <Button
                    type="button"
                    name="createAddon"
                    onClick={createAddon.bind(this)}
                    title="Neues Addon erstellen"
                    variant="outline-success"
                >Neues Addon erstellen</Button>
            </div>
        </>
    );
}

type BackButtonProps = {
    backTo: Function,
}

export function BackButton({backTo}: BackButtonProps) {
    return(
        <Button
            className="height-42 mt-2 ml-3"
            type="button"
            name="back"
            onClick={backTo}
            title="Zurück"
            variant="outline-light"
        >
            Zurück zur Übersicht
        </Button>
    );
}

type FormButtonsProps = {
    submit: Function,
    addonLoaded: boolean,
}

export function SubmitButton({submit, addonLoaded}: FormButtonsProps) {
    return(
        <Button
            disabled={!addonLoaded}
            className="border-light height-42 mt-2 mr-3"
            type="submit"
            name="save"
            onClick={submit}
            title="Speichern"
            variant="success"
        >
            Speichern
        </Button>
    );
}

type CreateRecordButtonProps = {
    name: string,
    title: string,
    createFunction: Function,
}

export function CreateRecordButton({name, title, createFunction}): CreateRecordButtonProps {
    return (
        <Button
            type="button"
            name={name}
            onClick={() => createFunction()}
            title={title + " hinzufügen"}
            variant="outline-success"
        >{title} hinzufügen</Button>
    );
}

type DeleteRecordButtonProps = {
    name: string,
    className: string,
    setShowConfirmDialog: Function,
    index: ?number,
}

export function DeleteRecordButton({name, className, setShowConfirmDialog, index}): DeleteRecordButtonProps {
    if (index === 0) {
        return (
            <div className={className} />
        );
    }

    return (
        <div className={className}>
            <Button
                size="sm"
                type="button"
                name={name}
                onClick={() => setShowConfirmDialog(true)}
                title="Eintrag löschen"
                variant="outline-danger"
            >–</Button>
        </div>
    );
}

type XlsxExportButtonsProps = {
    xlsxExportDataAddonIds: [],
    addonListXlsxExport: Function,
}

export function XlsxExportButton({xlsxExportDataAddonIds, addonListXlsxExport}: XlsxExportButtonsProps) {
    return (
        <div className="mt-4">
            <Button
                type="button"
                variant="outline-dark"
                disabled={xlsxExportDataAddonIds.length === 0}
                onClick={addonListXlsxExport}
            >Auswahl exportieren</Button>
        </div>
    );
}
